<template>
  <div class="info">
    <button 
      
      type="button"
      class="info-button" 
      :class="{visible: !visible}" 
      @click="visible = !visible"
    >
    <span class="u-visually-hidden">Ouvrir la modale d'information</span>
      ?
    </button>
    <button 
      type="button"
      class="info-button-close" 
      :class="{visible}" 
      @click="visible = !visible"
    >
      <svg width="10" height="10" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94971 6.36415L0.000152588 11.3137L1.41437 12.7279L6.36392 7.77837L11.3137 12.7282L12.7279 11.3139L7.77813 6.36415L12.7281 1.41421L11.3139 0L6.36392 4.94994L1.41422 0.000239372L0 1.41445L4.94971 6.36415Z" fill="#272838"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94971 6.36415L0.000152588 11.3137L1.41437 12.7279L6.36392 7.77837L11.3137 12.7282L12.7279 11.3139L7.77813 6.36415L12.7281 1.41421L11.3139 0L6.36392 4.94994L1.41422 0.000239372L0 1.41445L4.94971 6.36415Z" fill="#1D1E39"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94971 6.36415L0.000152588 11.3137L1.41437 12.7279L6.36392 7.77837L11.3137 12.7282L12.7279 11.3139L7.77813 6.36415L12.7281 1.41421L11.3139 0L6.36392 4.94994L1.41422 0.000239372L0 1.41445L4.94971 6.36415Z" fill="#1B1D36"/>
      </svg>
      <span class="u-visually-hidden">Fermer la modale d'information</span>
    </button>
    <aside class="info-modal" :class={visible}>
      <section ref="container">
        <h1 ref="suptitle" class="modal-suptitle">{{info?.suptitle}}</h1>
        <h2 ref="title" class="modal-title">
          {{info?.title}}
        </h2>
        <p ref="description" class="modal-description">{{info?.description}}</p>
        <Btn 
          v-if="info" 
          :link="info?.link"
        />
      </section>
    </aside>
  </div>
</template>

<script>
import info from '@/core/data/info.json'
import Btn from './Btn.vue';

export default {
  name: "Info",
  components: { 
    Btn
  },
  data() {
    return {
      info:info.about,
      visible: false
    }
  },
  mounted() {
    document.addEventListener('click', (evt) => {
      const { target } = evt
      if(target != this.$el && target.closest('.info') != this.$el && this.visible) this.visible = false
    })
  }
}
</script>

<style lang="scss">
  .info {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 12;

    opacity: 0;
    transform: translateX(10px);
    transition: opacity .3s .4s $ease-out-quad, transform .3s .4s $ease-out-quad;
    
    pointer-events: none;

    @include media-min(m) {
      top: 40px;
      right: 40px;
    }

    &.isVisible {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0);
    }

    &-button {
      width: 46px;
      height: 46px;

      box-shadow: 0px 2px 3px rgba($black, 0);
      background: $white;

      border-radius: 50%;

      font-family: $primary;
      font-weight: 600;
      font-size: rem(16px);
      color: $black;

      transition: box-shadow .3s $ease-out-quad, background .3s $ease-out-quad;
      &.visible {
        box-shadow: 0px 2px 3px rgba($black, .12);
        pointer-events: auto;
        background: $lightblue;

        transition: box-shadow .3s .6s $ease-out-quad, background .3s .6s $ease-out-quad;
      }

      &-close {
        width: 25px;
        height: 25px;

        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;

         background: $white;

        opacity: 0;
        pointer-events: none;

        transition: opacity .3s .3s $ease-out-quad;

        svg {
          opacity: 0;
          transition: opacity .3s $ease-out-quad;
        }

        &.visible {
          opacity: 1;
          pointer-events: auto;
          transition: opacity .3s  $ease-out-quad;

          svg {
            opacity: 1;
            transition: opacity .3s .3s  $ease-out-quad;
          }
        }
      }
    }

    &-modal {
      width: 345px;
      max-width: calc(100vw - 40px);
      position: absolute;
      top: 0;
      right: 0;

      box-shadow: 0px 2px 3px rgba($black, 0), 0px 0px 24px rgba($darkgrey, 0);
      border-radius: 20px;

      padding: 40px;

      text-align: center;

      /* opacity: 0; */
      pointer-events: none;
      overflow: hidden;

      transform-origin: top right;
      transition: box-shadow .3s  .3s $ease-out-quad ;

      

      &:before {
        content: '';

        display: block;
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;

        border-radius: 50%;

        background: $white;

        width: 46px;
        height: 46px;

        opacity: 0;
        pointer-events: none;
        transform-origin: 40px 8px;

        transition: opacity .3s .7s $ease-out-quad, transform .3s .4s $ease-out-quad;
      }

      &.visible {
        
        pointer-events: auto;
        box-shadow: 0px 2px 3px rgba($black, .1), 0px 0px 24px rgba($darkgrey, 0.08);

        transition: box-shadow .3s .3s $ease-out-quad;

        &:before {
          opacity: 1;
          transform: scale(25);
          transition: opacity .3s $ease-out-quad,  transform 1s $ease-out-quad;
        }

        .modal-suptitle,
        .modal-title,
        .btn,
        .modal-description {
           opacity: 1;
           transition: opacity .3s .3s $ease-out-quad;
        }
      }
    }

    .modal-suptitle,
    .modal-title,
    .btn,
    .modal-description {
      position: relative;
      z-index: 1;
      opacity: 0;
      transition: opacity .3s $ease-out-quad;
    }

    .modal-suptitle {
      font-weight: 600;
    }
    .modal-title {
      font-size: rem(24px);
      font-weight: 300;
      letter-spacing: -.02rem;
      text-transform: uppercase;
      line-height: 1.3;
      @include media-min(m) {
        font-size: rem(36px);
      } 
    }
  }
</style>