<template>
  <aside class="modal" :class={visible}>
    <a class="modal-logo" href="https://laniche.com" target="_blank" rel="noopener">
      <svg width="36" height="38" viewBox="0 0 389 428" fill="none" >
      <path d="M158.254 -2.01725e-05C171.075 -1.90516e-05 183.071 1.03739 194.277 3.00847L194.483 3.04305L194.689 3.00847C205.929 1.03739 217.925 -1.49558e-05 230.712 -1.3838e-05C283.922 -9.18625e-06 323.382 17.3247 349.643 49.4844C375.904 81.6094 389 128.742 389 188.532L389 411.229L389 428L372.02 428L317.676 428L300.696 428L300.696 411.229L300.696 189.465C300.764 95.8911 263.504 36.1364 194.723 20.679L194.483 20.6098L194.242 20.679C125.496 36.1364 88.2357 95.8911 88.3044 189.465L88.3044 428L87.1701 428L71.3241 428L16.9803 428L2.06238 428L-6.62315e-06 428L1.43118e-05 188.532C1.95358e-05 128.777 13.0961 81.644 39.3572 49.4843C65.6182 17.3247 105.044 -2.48242e-05 158.254 -2.01725e-05ZM241.058 19.192C291.484 47.375 316.507 105.816 316.542 188.532L316.542 412.37L373.154 412.37L373.154 189.465C373.154 137.145 361.605 95.4416 339.331 66.0829C317.057 36.7242 284.093 19.8145 241.677 17.0481L236.624 16.7023L241.058 19.192ZM15.8116 411.194L15.8116 412.335L72.4584 412.335L72.4584 188.532C72.4584 105.816 97.5164 47.3749 147.942 19.192L152.376 16.7023L147.323 17.0481C104.907 19.8145 71.9428 36.7242 49.6691 66.0829C27.3953 95.4415 15.846 137.145 15.846 189.465L15.846 411.194L15.8116 411.194Z" fill="#272838" shape-rendering="geometricPrecision"/>
      <path d="M158.254 -2.01725e-05C171.075 -1.90516e-05 183.071 1.03739 194.277 3.00847L194.483 3.04305L194.689 3.00847C205.929 1.03739 217.925 -1.49558e-05 230.712 -1.3838e-05C283.922 -9.18625e-06 323.382 17.3247 349.643 49.4844C375.904 81.6094 389 128.742 389 188.532L389 411.229L389 428L372.02 428L317.676 428L300.696 428L300.696 411.229L300.696 189.465C300.764 95.8911 263.504 36.1364 194.723 20.679L194.483 20.6098L194.242 20.679C125.496 36.1364 88.2357 95.8911 88.3044 189.465L88.3044 428L87.1701 428L71.3241 428L16.9803 428L2.06238 428L-6.62315e-06 428L1.43118e-05 188.532C1.95358e-05 128.777 13.0961 81.644 39.3572 49.4843C65.6182 17.3247 105.044 -2.48242e-05 158.254 -2.01725e-05ZM241.058 19.192C291.484 47.375 316.507 105.816 316.542 188.532L316.542 412.37L373.154 412.37L373.154 189.465C373.154 137.145 361.605 95.4416 339.331 66.0829C317.057 36.7242 284.093 19.8145 241.677 17.0481L236.624 16.7023L241.058 19.192ZM15.8116 411.194L15.8116 412.335L72.4584 412.335L72.4584 188.532C72.4584 105.816 97.5164 47.3749 147.942 19.192L152.376 16.7023L147.323 17.0481C104.907 19.8145 71.9428 36.7242 49.6691 66.0829C27.3953 95.4415 15.846 137.145 15.846 189.465L15.846 411.194L15.8116 411.194Z" fill="#272838" shape-rendering="geometricPrecision"/>
      <path d="M158.254 -2.01725e-05C171.075 -1.90516e-05 183.071 1.03739 194.277 3.00847L194.483 3.04305L194.689 3.00847C205.929 1.03739 217.925 -1.49558e-05 230.712 -1.3838e-05C283.922 -9.18625e-06 323.382 17.3247 349.643 49.4844C375.904 81.6094 389 128.742 389 188.532L389 411.229L389 428L372.02 428L317.676 428L300.696 428L300.696 411.229L300.696 189.465C300.764 95.8911 263.504 36.1364 194.723 20.679L194.483 20.6098L194.242 20.679C125.496 36.1364 88.2357 95.8911 88.3044 189.465L88.3044 428L87.1701 428L71.3241 428L16.9803 428L2.06238 428L-6.62315e-06 428L1.43118e-05 188.532C1.95358e-05 128.777 13.0961 81.644 39.3572 49.4843C65.6182 17.3247 105.044 -2.48242e-05 158.254 -2.01725e-05ZM241.058 19.192C291.484 47.375 316.507 105.816 316.542 188.532L316.542 412.37L373.154 412.37L373.154 189.465C373.154 137.145 361.605 95.4416 339.331 66.0829C317.057 36.7242 284.093 19.8145 241.677 17.0481L236.624 16.7023L241.058 19.192ZM15.8116 411.194L15.8116 412.335L72.4584 412.335L72.4584 188.532C72.4584 105.816 97.5164 47.3749 147.942 19.192L152.376 16.7023L147.323 17.0481C104.907 19.8145 71.9428 36.7242 49.6691 66.0829C27.3953 95.4415 15.846 137.145 15.846 189.465L15.846 411.194L15.8116 411.194Z" fill="#272838" shape-rendering="geometricPrecision"/>
      </svg>
      <span class="u-visually-hidden">La niche</span>

    </a>
    <section class="modal-container" ref="container">
      <h1 ref="suptitle" class="modal-suptitle">{{item?.suptitle}}</h1>
      <h2 ref="title" class="modal-title" :class="item?.class">
        <template v-if="item?.class==='info-counter'">
          <Counter />
        </template>
        <template v-else>
          <span class="u-visually-hidden">{{item?.title}}</span>
          <Sprite v-if="item" :icon="item?.id"/>
        </template>
        
      </h2>
      <p ref="description" class="modal-description" v-html="item?.description" />
      
       <template  v-if="item && item.btns" >  
        <Btn 
          v-for="(btn, index) in item.btns"
          :key="'btn-' + index + btn.text"
          @click="btn.id !== 'end' ? $emit('clicked', btn.id) : ''" 
          :button=" !btn.url ? btn : '' "
          :link="btn.url ? btn : '' "
        />
      </template>
      <Btn 
        v-else-if="item"
        @click="item.id !== 'end' ? $emit('clicked', item?.button?.id) : ''" 
        :button="item?.button"
        :link="item?.link"
      />
      <!-- <Btn 
        v-if="item" 
        @click="item.id !== 'end' ? $emit('clicked', item?.button?.id) : ''" 
        :button="item?.button"
        :link="item?.link"
      /> -->
      <div class="modal-links" v-if="item?.links" >
        <a 
          :key="item.id+link.url"
          v-for="link in item.links"
          :href="link.url"
          target="_blank"
          rel="noopener"
        >
          <Sprite v-if="link" :icon="'arrow'"/>
          <span>{{link.text}}</span>
        </a>
      </div>

    <div class="modal-socials" v-if="item?.social" >
      <a 
        :key="item.id+link.url"
        v-for="link in item.social"
        :href="link.url"
        target="_blank"
        rel="noopener"
      >
        <Sprite v-if="link" :icon="link.type"/>
        <span class="u-visually-hidden">{{link.type}}</span>
      </a>
    </div>
    </section>
  </aside>
</template>

<script>
import gsap from '@/assets/_Lib/gsap.min.js'
import SplitText from '@/assets/_Lib/SplitText.min.js'

import Btn from './Btn.vue';
import Sprite from './Spritesheet.vue';
import Counter from './Counter.vue';

gsap.registerPlugin(SplitText)

export default {
  name: "modal",
  components: { 
    Btn,
    Sprite,
    Counter
  },
  computed: {
    total_counter() {
      return this.$store.state.global.total_counter
    },
    counter() {
      return this.$store.state.global.counter
    },
    visible() {
      return this.$store.state.global.modal.visible
    },
    item() {
      return this.$store.state.global.modal.content
    },
    id() {
      return this.$store.state.global.modal.id
    },
    background() {
      return this.$store.state.global.background
    },
  },
  mounted() {
    this.container = this.$refs.container
    this.$el.style.maxHeight = (window.APP.height - 80) + 'px'
    this.container.style.maxHeight = (window.APP.height - 180) + 'px'
    this.padding = window.APP.isMobile ? 80 : 120;
    this.hide()
  },
  watch: {
    id(value) {
      if(value === "end") this.$el.style.zIndex = 20
      if(value) this.handleChange(value) 
    }
  },
  methods: {
    dispatchModal(value) {
      return this.$store.commit('global/SET_MODAL', value)
    },
    setDesc() {
      this.$store.commit('global/SET_MODAL_VISIBLE', true)
    },
    async handleChange(value) {
      if(this.item) await this.hide();
      await this.dispatchModal(value)
      await this.size()
      await this.setDesc()
      await this.show()

      const isDrag = this.$el.querySelector('.info-drag');
      const isCounter = this.$el.querySelector('.info-counter');

      if(isDrag) this.handleDrag(isDrag)
      if(isCounter && this.id === "step-2") {
        
        this.handleCounter(isCounter)
      }
    },
    hide() {
      this.$title = this.container.querySelector('.modal .modal-title')

      this.$suptitle = this.container.querySelector('.modal .modal-suptitle')
      this.$description = this.container.querySelector('.modal .modal-description')
      this.splitted = new SplitText(this.$description, { type: "words" })

      return gsap.to([this.$title, this.$suptitle,this.$description, ...this.splitted.words], {
        opacity: 0,
        y: 10,
        duration: .8,
        force3D: true,
        stagger: {
          amount: 0.1
        },
        ease: "power3.out",
        onComplete: () => document.removeEventListener('click', this.outsideClick),
      })
    },
    size() {
      this.container.removeAttribute('style')
      this.container.classList.remove('max')

      

      if(this.id && this.id.match('step') || this.visible) {
        return gsap.to( this.$el, {
          height: this.container.clientHeight + this.padding ,
          ease: "power3.out",
          duration: .2,
          onComplete: () => {
            this.container.style.height = this.container.scrollHeight + "px"
            if(this.container.clientHeight + 120 > window.APP.height - this.padding ) this.container.classList.add('max')
          }
        })
      } else {
        return gsap.set( this.$el, {
          height: this.container.clientHeight + this.padding,
          onComplete: () => {
            this.container.style.height = this.container.scrollHeight + "px"
            if(this.container.clientHeight + 120 > window.APP.height - this.padding ) this.container.classList.add('max')
          }
        })
      }
    },
    show() {
      this.$title = this.container.querySelector('.modal .modal-title')
      this.$suptitle = this.container.querySelector('.modal .modal-suptitle')

      this.$description = this.container.querySelector('.modal .modal-description')
      this.splitted = new SplitText(this.$description, { type: "words" })
      gsap.set(this.splitted.words, { opacity: 0, y: 30,force3D: true, })

      return gsap.to([this.$title, this.$suptitle,this.$description, ...this.splitted.words], {
        duration: .8,
        opacity: 1,
        y: 0,
        force3D: true,
        stagger: {
          amount: 0.3
        },
        ease: "power3.out",
        onComplete: () => document.addEventListener('click', this.outsideClick)
      })
      
    },
    outsideClick({ target }) {
      const hasGroup = target.closest('.group');
      const isModal = target.closest('.modal') != this.$el;

      if(( !hasGroup || hasGroup && this.item && hasGroup.dataset.id != this.item?.id) && target != this.$el && isModal && this.visible && !this.background) {
        this.hide()
        this.$store.commit('global/SET_MODAL_VISIBLE', false)
        this.$emit('clicked', this.item?.button?.id)
      }
    },

    handleDrag($el) {
      const icon = $el.querySelector('svg')
      const tl = gsap.timeline({repeat: -1, repeatDelay: 0})

      tl.to(icon, { x: -60, y: 0, ease: "linear", duration: .6})
      tl.to(icon, { x: 0, y: 0, ease: "linear", duration: .6})
      tl.to(icon, { x: 60, y: 0, ease: "linear", duration: .6 })
      tl.to(icon, { x: 0, y: 0, ease: "linear", duration: .6})

      $el.addEventListener('mouseleave', (evt) => {
        
        gsap.to(icon,{
          duration: .3,
          x: 0,
          y: 0,
          force3D: true,
          ease: "power3.out",
          onComplete:() => tl.restart()
        })
      })
      $el.addEventListener('mousemove', (evt) => {
        tl.pause()
        const {clientY, clientX} = evt
        const {x, y, width, height} = $el.getBoundingClientRect()
        const cursorY =  clientY - y - height / 2
        const cursorX = clientX - x - width / 2
        gsap.to(icon,{
          duration: .3,
          x: cursorX,
          y: cursorY,
          force3D: true,
          ease: "power3.out"
        })
      })
    },

    handleCounter($el) {
      setTimeout(() => {
        this.$store.commit('global/UPDATE_COUNTER');
        $el.click()
        if(this.counter < this.total_counter && this.id === "step-2") this.handleCounter($el)
      }, 1500)
    }
  }
}
</script>

<style lang="scss">
  .modal {
    width: 100%;
    max-width: calc(100% - 20px);

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 13;
    background: $white;

    text-align: center;

    transform: translateX(-50%) translateY(calc(-50% + 10px));

    box-shadow: 0px 2px 3px rgba($black, .1), 0px 0px 24px rgba($darkgrey, 0.08);
    border-radius: 30px;

    padding: 60px 20px 20px;

    opacity: 0;
    pointer-events: none;


    transition: opacity .3s $ease-out-quad,transform .3s $ease-out-quad;
    @include media-min(xs) {
      max-width: calc(100% - 40px);
    }
    @include media-min(m) {
      width: 500px;
    }
    &.visible {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(-50%)  translateY(-50%);
    }
    .info-counter {
      margin: 40px auto;
      height: auto;
      position: inherit;
      cursor: pointer;
      .counter {
        transform: translate(0,0); 
        position: inherit;
        opacity: 1;
      }
    }

    &-container {
      @include media-max(l) {
        overflow: auto;
        padding-bottom: 5px;
        &.max {
          max-height: 100%;
        }
      }
      /* overflow: auto; */
    }

    

    @include media-min(l) {
      padding: 60px;
      max-width: 500px;
    }

    &-logo {
      position: absolute;
      top: 0;
      left: 50%;
      
      width: 80px;
      height: 80px;

      transform: translateX(-50%)  translateY(-50%);

      background: $white;
      padding: 20px 22px;
      
      border: 1px solid $grey;
      border-radius: 50%;
      transition: box-shadow .3s $ease-out-quad;

      &:hover {
        box-shadow: 0px 2px 3px rgba($black, 0.1), 0px 0px 24px rgba($darkgrey, 0.08);
      }
      
    }

    &-suptitle {
      font-family: $primary;
      font-size: rem(15px);
      font-weight: 400;
      color:$lightgrey;
      letter-spacing: -0.02em;

    }

    &-title {
      font-family: $primary;
      font-size: rem(74px);
      font-weight: 300;

      
      text-align: center;
      

      display: flex;
      justify-content: center;
      align-items: center;

      height: 72px;
      @include media-max(l) {
        margin: 10px auto;
      }
      @include media-min(l) {
        height: 150px;
      }
      .icon {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
      &.info-drag:hover {
        cursor: none;
      }
      .icon:not(.icon-step-3) {
        pointer-events: none;
        svg {
          height: 100%;
          @include media-max(s) {
            max-width: calc(100% - 80px);
          }
        }
      }
    }

    &-description {
      font-family: $primary;
      font-size: rem(15px);
      font-weight: 400;
      color: $black;
      letter-spacing: -0.02em;
      line-height: 24px;

      margin-bottom: 20px;

      @include media-min(l) {
        margin-bottom: 40px;
      }
      @include media-minmax(xs, l) {
        padding: 0 20px;
        /* max-width: calc(100% - 80px); */
        margin: 0 auto 20px;
      }

      .text-pink {
        color: $pink;
      }
    }


    &-links {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      margin-top: 20px;
      margin-bottom: 40px;
      @include media-min(l) {
        margin-top: 40px;
      }
      a {
        position: relative;
        overflow: hidden;
        display: block;
        /* width: 50%; */

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: rem(16px);
        font-weight: 600;
        letter-spacing: -0.02em;
        color:$black;

        padding: 10px 20px 10px 10px;
        border-radius: 30px;  
        transition: opacity .3s $ease-out-quad, box-shadow .3s $ease-out-quad;

        .icon {
          margin-right: 10px;
          transition: transform .3s $ease-out-quad;
        }

        span {
          z-index: 2;
        }
        &:hover {
          box-shadow: 0px 2px 3px rgba($black, 0.05), 0px 0px 24px rgba($darkgrey, 0.08);
          .icon {
            transform: translateX(5px);
          }
        }
      }
    }

    &-socials {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        fill: $black;
        transition: fill .3s $ease-out-quad;
      }
      
      a {
        display: block;
        padding: 0 13px;

        &:nth-child(1) {
          &:hover .icon { fill: $hover1}
        }
        &:nth-child(2) {
          &:hover .icon { fill: $hover2}
        }
        &:nth-child(3) {
          &:hover .icon { fill: $hover3}
        }
        &:nth-child(4) {
          &:hover .icon { fill: $hover4}
        }
        &:nth-child(5) {
          &:hover .icon { fill: $hover5}
        }
      }
    }
  }
</style>