<template>
  <div class="counter">
    <span class="counter-count"> {{counter}}/{{total_counter}}</span>
    <svg class="counter-svg" width="141" height="58" viewBox="0 0 141 58" fill="none">
      <circle cx="18" cy="8" r="3" stroke="white" stroke-width="2"/>
      <line x1="106.293" y1="7.29289" x2="112.293" y2="1.29289" stroke="white" stroke-width="2"/>
      <line x1="12.6" y1="48.8" x2="4.6" y2="54.8" stroke="white" stroke-width="2"/>
      <line x1="9.35112" y1="43.9363" x2="1.35112" y2="46.9363" stroke="white" stroke-width="2"/>
      <line x1="8.87597" y1="36.9923" x2="0.875965" y2="35.9923" stroke="white" stroke-width="2"/>
      <circle cx="134" cy="30" r="6" stroke="white" stroke-width="2"/>
      <circle cx="104" cy="54" r="3" stroke="white" stroke-width="2"/>
    </svg>
  </div>
</template>

<script>
import gsap from '@/assets/_Lib/gsap.min.js'
import CustomEase from '@/assets/_Lib/CustomEase.min.js'
import CustomWiggle from '@/assets/_Lib/CustomWiggle.min.js'
gsap.registerPlugin(CustomEase, CustomWiggle);

import AudioSong from '@/assets/audio/found.mp3'
export default {
  name: "Counter",
  computed: {
    total_counter() {
      return this.$store.state.global.total_counter;
    },
    counter() {
      return this.$store.state.global.counter;
    },
    isPlay() {
      return this.$store.state.global.audio
    },
    background() {
      return this.$store.state.global.background
    },
  },
  watch: {
    counter() {
      this.$audio.pause()
      this.tl.play(0)
      if(this.isPlay && !this.background && this.counter > 0) this.$audio.play()
    }
  },
  mounted() {
    this.$audio = new Audio(AudioSong)
    this.$audio.volume = .6
    this.handleCounter()
    this.tl.play(0)
  },
  methods: {
    handleCounter() {
      CustomWiggle.create("Wiggle.easeOut", {wiggles:2, type:"easeOut"});
      this.tl = gsap.timeline({ repeat: 2, paused: true });
      this.tl.to(this.$el, {
        duration:.2, 
        rotation:10, 
        ease: "Wiggle.easeOut"
      })
    }
  }
}
</script>

<style lang="scss">
  .counter {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 11;

    width: 178px; 
    height: 72px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: $pink;
    border-radius: 10px;
    
    box-shadow: 0px 2px 3px rgba($black, .12);


    opacity: 0;
    pointer-events: none;
    transition: opacity .3s .5s $ease-out-quad;

    @include media-min(m) {
      top: 50px;
      left: 50px;
      /* transform: translateX(-20px) translateY(-50%); */
    }
    &.isVisible {
      opacity: 1;
      pointer-events: auto;
      @include media-min(m) {
        /* transform: translateX(0) translateY(-50%); */
      }
    }

    &-count {
      color: $white;
      font-family: $primary;
      font-size: rem(25px);
      font-weight: 500;
    }
    
    &-svg {
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translateX(-50%) translateY(-50%);
    }
  }
</style>