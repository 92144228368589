import steps from '@/core/data/tutorial.json'
import facts from '@/core/data/facts.json'
import hover from '@/core/data/hover.json'

export const global = {
  namespaced: true,
  state: {
    total_counter: 22,
    counter: 10,
    background: true,
    canClick: true,
    move: false,
    modal: {
      content: null,
      visible: false,
      id: null,
    },
    tooltip: {
      content: null,
      visible: false,
      id: null,
      coord: null,
    },
    step: {
      visible:false
    },
    contents: [
      ...steps,
      ...facts,
    ],
    hover: hover,
    found: [],
    audio: null,
  },
  mutations: {
    SET_MODAL(state, item) {
      state.modal.content = state.contents.filter(content => content.id === item).shift();
    },
    SET_TOOLTIP(state, item) {
      state.tooltip.content = state.hover.filter(content => content.id === item).shift();
    },
    SET_MODAL_VISIBLE(state, visible) {
      state.modal.visible = visible
    },
    SET_TOOLTIP_VISIBLE(state, visible) {
      state.tooltip.visible = visible
    },
    SET_TOOLTIP_COORD(state, coord) {
      state.tooltip.coord = coord
    },
    SET_STEP_VISIBLE(state, visible) {
      state.step.visible = visible
    },
    SET_BACKGROUND(state, background) {
      state.background = background
    },
    SET_ID(state, id) {
      state.modal.id = id
    },
    SET_ID_TOOLTIP(state, id) {
      state.tooltip.id = id
    },
    SET_MOVE(state, move) {
      state.move = move
    },
    UPDATE_AUDIO(state, audio) {
      state.audio = audio
    },
    SET_COUNTER(state, counter) {
      state.counter = counter
    },
    SET_CANCLICK(state, bool) {
      state.canClick = bool
    },
    NEW_FOUND(state, id) {
      state.found.push(id)
    },
    SET_FOUND(state, found) {
      state.found = found
    },
    UPDATE_COUNTER(state) {
      state.counter++
    }
  },
  actions: {
    get_found({commit}) {
      let facts = localStorage.getItem('fact')
      if(facts) {
        facts = JSON.parse(facts);
        commit('SET_FOUND',  facts)
      }
    },
    set_found({commit, state}, id) {
      if(state.found.indexOf(id) === -1) {
        commit('NEW_FOUND',  id)
        localStorage.setItem('fact', JSON.stringify(state.found))
        commit('SET_COUNTER', state.found.length)
      }
    },
    clear_found() {
      localStorage.removeItem('fact')
    }
  },
}