<template>
  <div class="cursors">
    <audio
      ref="audio"
      :src="audio">
          Your browser does not support the
          <code>audio</code> element.
      </audio>
  </div>
</template>

<script>
import gsap from '@/assets/_Lib/gsap.min.js'
import AudioSong from '@/assets/audio/touch.mp3'
export default {
  name: "Cursor",
  computed: {
    id() {
      return this.$store.state.global.modal.id
    },
  },
  data() {
    return {
      width: 150,
      height: 150,
      length: 0,
      classes: [
        "circle",
        "lines",
        "line",
        "circle",
        "line",
        "circle",
        "line"
      ],
      colors: [
        "blue",
        "black", 
        "pink"
      ],
      audio: AudioSong,
    }
  },
  watch: {
    id(value) {
      if(value === "end") this.randomClick()
    }
  },
  mounted() {
    // this.$audio = this.$refs.audio 
    // this.$audio.volume = .7
    // window.addEventListener('mousemove', () => {
    //   })
    window.addEventListener('click', (evt) => {
      this.length = Math.floor(gsap.utils.random(4, 7))
      this.createShapes(evt)
    })
    document.addEventListener('touchstart', (evt) => {
      this.length = Math.floor(gsap.utils.random(4, 7))
      this.createShapes(evt.touches[0])
    });
  },
  methods: {
    randomClick() {
      this.interval = setInterval(this.handleRandomClick, 700);

      document.body.addEventListener('mouseout', (evt) => {
          if (!evt.relatedTarget && !evt.toElement) {
            clearInterval(this.interval)
            this.interval = null
          }
      });
      document.body.addEventListener('mouseenter', (evt) => {
          if(!this.interval) this.interval = setInterval(this.handleRandomClick, 700);
      });
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState == "visible") {
          if(!this.interval) this.interval = setInterval(this.handleRandomClick, 700);
        } else {
           clearInterval(this.interval)
           this.interval = null
        }
      });

    },
    handleRandomClick() {
       const x1 = gsap.utils.random(0, window.innerWidth)
        const y1 = gsap.utils.random(0, window.innerHeight)
        const x2 = gsap.utils.random(0, window.innerWidth)
        const y2 = gsap.utils.random(0, window.innerHeight)
        this.createShapes({clientX: x1, clientY: y1, target:this.$el,  duration: 1.2})
        this.createShapes({clientX: x1, clientY: y1, target:this.$el, delay: .1, duration: 1.2})
        this.createShapes({clientX: x2, clientY: y2, target:this.$el, delay: .3,duration: 1.2})
    },
    createShapes({clientX, clientY, target, delay, duration}) {
      delay = delay ?? 0
      duration = duration ?? 1
      const $shapes = []
      const cursor = document.createElement('div')
      cursor.classList.add('cursor')

      let cursorY = clientY - this.width/2
      let cursorX = clientX - this.height/2

      const btn = target.closest(".btn")
      if(btn && btn.parentNode) {
        btn.parentNode.append(cursor)
        const {top, left, width, height } = btn.getBoundingClientRect()
        cursorY = btn.offsetTop + height/2 - this.height /2
        cursorX = btn.offsetLeft + width/2 - this.width /2
      }
      else if(!clientX && !clientY) {
        target.parentNode.append(cursor)
        const {y, x,width, height, top, left} = target.getBoundingClientRect()
        cursorY = target.offsetTop + height/2 - this.height /2
        cursorX = target.offsetLeft + width/2 - this.width /2
      }
      else this.$el.append(cursor)

      gsap.set(cursor, {
        top: cursorY,
        left: cursorX,
      })
      for (let index = 0; index < this.length ; index++) {
        const $el = document.createElement('div')
        const className = this.classes[Math.floor(gsap.utils.random(0, 7))]
        const color = this.colors[Math.floor(gsap.utils.random(0, 3))]
        $el.classList.add('cursor-item')
        $el.classList.add(className)
        $el.classList.add(color)

        const wH = gsap.utils.random(10, 25)
        if(className === "circle") {
          $el.style.width = wH + "px"
          $el.style.height = wH + "px"
        } 
        if(className === "line") {
          $el.style.height = wH + "px"
        } 
        if(className === "lines") {
          // for (let index = 0; index < 3; index++) {
            const $line = document.createElement('div')
            $line.classList.add(color)
            $line.classList.add("line")
            $line.style.height = wH + "px"
            $el.append($line)
          // }
          $el.style.height = wH + "px"
        } 

        $shapes.push($el)
        cursor.append($el)
      }

      // this.$audio.volume = gsap.utils.random(.1, .7)
      gsap.set($shapes, { opacity: 0 })
      const tl = gsap.timeline({
        delay,
        // onStart: () => this.$audio.play()
      })
      tl.to($shapes, {
        opacity: 1,
        duration: .2,
        ease: "power3.out",
      })
      tl.to($shapes, {
        x: () => Math.floor(gsap.utils.random(-this.width, this.width)),
        y: () => Math.floor(gsap.utils.random(-this.height, this.height)),
        force3D: true,
        rotateZ: () => Math.floor(gsap.utils.random(-this.height, this.height)),
        stagger: {
          amount: .1,
        },
        ease: "power3.out",
        duration: .7 * duration,
      }, `-=${.2 * duration}`)
      tl.to($shapes, {
        opacity: 0,
         ease: "power3.out",
        onComplete: () => cursor.remove()
      }, `-=${.5}`)
    }
  }
}
</script>

<style lang="scss">
  .cursors {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    width: 100vw;
    height: 100vh;

    pointer-events: none;
  }
  .cursor {
    width: 150px;
    height: 150px;

    position: absolute;
    top:50%;
    left:50%;

    z-index: -1;

    &-item {
      position: absolute;
      top:50%;
      left:50%;

      opacity: 1;
      transition: opacity .2 $ease-out-quad;
    }
  }
  .circle {
    border: 2px solid $blue;
    border-radius: 50%;

    &.blue {
      border-color: $blue;
    }
    &.black {
      border-color: $mediumblue;
    }
    &.pink {
      border-color: $pink;
    }
  }

  .line {
    width: 2px;
    &.blue, .blue::before, .blue::after {
      background: $blue;
    }
    &.black, .black::before, .black::after  {
      background: $mediumblue;
    }
    &.pink, .pink::before, .pink::after  {
      background: $pink;
    }
  }
  .lines {
    display: flex;
    flex-wrap: wrap;

    transform-origin: top center;

    .line::before, .line::after {
      display: block;
      content: "";
      height: 100%;

      width: 2px;
      background: $blue;
    }
    .line::before {
      transform: rotate(9deg) translateX(7px);
    }
    .line::after {
      transform: rotate(-22deg) translateX(-3px)translateY(-13px)
    }

  }
</style>