<template>
  <aside class="step" :class={visible}>
    <button 
      type="button"
      class="step-close" 
      :class="{visible}" 
      @click="onClose"
    >
      <svg width="10" height="10" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94971 6.36415L0.000152588 11.3137L1.41437 12.7279L6.36392 7.77837L11.3137 12.7282L12.7279 11.3139L7.77813 6.36415L12.7281 1.41421L11.3139 0L6.36392 4.94994L1.41422 0.000239372L0 1.41445L4.94971 6.36415Z" fill="#272838"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94971 6.36415L0.000152588 11.3137L1.41437 12.7279L6.36392 7.77837L11.3137 12.7282L12.7279 11.3139L7.77813 6.36415L12.7281 1.41421L11.3139 0L6.36392 4.94994L1.41422 0.000239372L0 1.41445L4.94971 6.36415Z" fill="#1D1E39"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94971 6.36415L0.000152588 11.3137L1.41437 12.7279L6.36392 7.77837L11.3137 12.7282L12.7279 11.3139L7.77813 6.36415L12.7281 1.41421L11.3139 0L6.36392 4.94994L1.41422 0.000239372L0 1.41445L4.94971 6.36415Z" fill="#1B1D36"/>
      </svg>
      <span class="u-visually-hidden">Fermer la modale</span>
    </button>
    <a class="step-logo" href="https://laniche.com" target="_blank" rel="noopener">
      <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7591 0.869627C15.9055 0.869627 16.9781 0.962366 17.9801 1.13857L17.9985 1.14166L18.017 1.13857C19.022 0.962366 20.0946 0.869628 21.238 0.869628C25.9957 0.869628 29.5241 2.41836 31.8722 5.29326C34.2204 8.16507 35.3914 12.3785 35.3914 17.7233L35.3914 37.6312L35.3914 39.1305L33.8731 39.1305L29.0139 39.1305L27.4956 39.1305L27.4956 37.6312L27.4956 17.8068C27.5017 9.44177 24.1701 4.10002 18.02 2.71822L17.9985 2.71204L17.977 2.71822C11.83 4.10002 8.49838 9.44177 8.50453 17.8068L8.50452 39.1305L8.4031 39.1305L6.98622 39.1305L2.12705 39.1305L0.793156 39.1305L0.60875 39.1305L0.608752 17.7233C0.608752 12.3816 1.77975 8.16816 4.12789 5.29326C6.47603 2.41836 10.0013 0.869627 14.7591 0.869627ZM22.1631 2.58529C26.6719 5.10469 28.9094 10.329 28.9125 17.7233L28.9125 37.7332L33.9745 37.7332L33.9745 17.8068C33.9745 13.1297 32.9418 9.40159 30.9502 6.77708C28.9586 4.15258 26.0111 2.64094 22.2184 2.39363L21.7666 2.36272L22.1631 2.58529ZM2.02255 37.6281L2.02255 37.7302L7.08765 37.7302L7.08765 17.7233C7.08765 10.329 9.32822 5.10469 13.837 2.58529L14.2335 2.36272L13.7817 2.39363C9.98902 2.64093 7.04155 4.15257 5.04993 6.77708C3.05831 9.40158 2.02563 13.1297 2.02563 17.8068L2.02562 37.6281L2.02255 37.6281Z" fill="#272838"/>
        <path d="M14.7591 0.869627C15.9055 0.869627 16.9781 0.962366 17.9801 1.13857L17.9985 1.14166L18.017 1.13857C19.022 0.962366 20.0946 0.869628 21.238 0.869628C25.9957 0.869628 29.5241 2.41836 31.8722 5.29326C34.2204 8.16507 35.3914 12.3785 35.3914 17.7233L35.3914 37.6312L35.3914 39.1305L33.8731 39.1305L29.0139 39.1305L27.4956 39.1305L27.4956 37.6312L27.4956 17.8068C27.5017 9.44177 24.1701 4.10002 18.02 2.71822L17.9985 2.71204L17.977 2.71822C11.83 4.10002 8.49838 9.44177 8.50453 17.8068L8.50452 39.1305L8.4031 39.1305L6.98622 39.1305L2.12705 39.1305L0.793156 39.1305L0.60875 39.1305L0.608752 17.7233C0.608752 12.3816 1.77975 8.16816 4.12789 5.29326C6.47603 2.41836 10.0013 0.869627 14.7591 0.869627ZM22.1631 2.58529C26.6719 5.10469 28.9094 10.329 28.9125 17.7233L28.9125 37.7332L33.9745 37.7332L33.9745 17.8068C33.9745 13.1297 32.9418 9.40159 30.9502 6.77708C28.9586 4.15258 26.0111 2.64094 22.2184 2.39363L21.7666 2.36272L22.1631 2.58529ZM2.02255 37.6281L2.02255 37.7302L7.08765 37.7302L7.08765 17.7233C7.08765 10.329 9.32822 5.10469 13.837 2.58529L14.2335 2.36272L13.7817 2.39363C9.98902 2.64093 7.04155 4.15257 5.04993 6.77708C3.05831 9.40158 2.02563 13.1297 2.02563 17.8068L2.02562 37.6281L2.02255 37.6281Z" fill="#1D1E39"/>
        <path d="M14.7591 0.869627C15.9055 0.869627 16.9781 0.962366 17.9801 1.13857L17.9985 1.14166L18.017 1.13857C19.022 0.962366 20.0946 0.869628 21.238 0.869628C25.9957 0.869628 29.5241 2.41836 31.8722 5.29326C34.2204 8.16507 35.3914 12.3785 35.3914 17.7233L35.3914 37.6312L35.3914 39.1305L33.8731 39.1305L29.0139 39.1305L27.4956 39.1305L27.4956 37.6312L27.4956 17.8068C27.5017 9.44177 24.1701 4.10002 18.02 2.71822L17.9985 2.71204L17.977 2.71822C11.83 4.10002 8.49838 9.44177 8.50453 17.8068L8.50452 39.1305L8.4031 39.1305L6.98622 39.1305L2.12705 39.1305L0.793156 39.1305L0.60875 39.1305L0.608752 17.7233C0.608752 12.3816 1.77975 8.16816 4.12789 5.29326C6.47603 2.41836 10.0013 0.869627 14.7591 0.869627ZM22.1631 2.58529C26.6719 5.10469 28.9094 10.329 28.9125 17.7233L28.9125 37.7332L33.9745 37.7332L33.9745 17.8068C33.9745 13.1297 32.9418 9.40159 30.9502 6.77708C28.9586 4.15258 26.0111 2.64094 22.2184 2.39363L21.7666 2.36272L22.1631 2.58529ZM2.02255 37.6281L2.02255 37.7302L7.08765 37.7302L7.08765 17.7233C7.08765 10.329 9.32822 5.10469 13.837 2.58529L14.2335 2.36272L13.7817 2.39363C9.98902 2.64093 7.04155 4.15257 5.04993 6.77708C3.05831 9.40158 2.02563 13.1297 2.02563 17.8068L2.02562 37.6281L2.02255 37.6281Z" fill="#1B1D36"/>
      </svg>
      <span class="u-visually-hidden">La Niche</span>
    </a>
    <section ref="container">
      <h1 ref="suptitle" class="step-suptitle">{{counter}} / {{total_counter}}</h1>
      <h2 ref="title" class="step-title" :class="item?.class">
        <template v-if="item?.class==='info-counter'">
          <Counter />
        </template>
        <template v-else>
          <span class="u-visually-hidden">{{item?.title}}</span>
          <Sprite v-if="item" :icon="item?.id"/>
        </template>
        
      </h2>
      <p ref="description" class="step-description">{{set(item?.description)}}</p>
      <!-- <Btn 
        v-if="item" 
        @click="$emit('clicked', item?.button?.id)" 
        :link="item?.link"
      /> -->
      <template  v-if="item && item.btns" >  
        <Btn 
          v-for="(btn, index) in item.btns"
          :key="'btn-' + index + btn.text"
          @click="btn.id !== 'end' ? $emit('clicked', btn.id) : ''" 
          :button=" !btn.url ? btn : '' "
          :link="btn.url ? btn : '' "
        />
      </template>
    </section>

  </aside>
</template>

<script>
import item from '@/core/data/step.json'
import gsap from '@/assets/_Lib/gsap.min.js'
import SplitText from '@/assets/_Lib/SplitText.min.js'

import Btn from './Btn.vue';
import Sprite from './Spritesheet.vue';
import Counter from './Counter.vue';

gsap.registerPlugin(SplitText)

export default {
  name: "step",
  components: { 
    Btn,
    Sprite,
    Counter
  },
  computed: {
    total_counter() {
      return this.$store.state.global.total_counter
    },
    counter() {
      return this.$store.state.global.counter
    },
    visible() {
      return this.$store.state.global.step.visible
    }
  },
  data() {
    return {
      item
    }
  },
  watch: {
    visible(value) {
      if(value) this.show()
      else this.hide()
    }
  },
  mounted() {
    this.container = this.$refs.container
  },
  methods: {
    onClose() {
      this.hide()
      this.$store.commit('global/SET_STEP_VISIBLE', false)
    },
    set(string) {
      return string.replace('{counter}', this.counter)
    },
    hide() {
      this.$title = this.container.querySelector('.step .step-title')

      this.$suptitle = this.container.querySelector('.step .step-suptitle')
      this.$description = this.container.querySelector('.step .step-description')
      this.splitted = new SplitText(this.$description, { type: "words" })

      gsap.to([this.$title, this.$suptitle,this.$description, ...this.splitted.words], {
        opacity: 0,
        y: 10,
        duration: .8,
        force3D: true,
        stagger: {
          amount: 0.1
        },
        ease: "power3.out",
        onComplete: () => document.removeEventListener('click', this.outsideClick)
      })
    },
    
    show() {
      this.$title = this.container.querySelector('.step .step-title')
      this.$suptitle = this.container.querySelector('.step .step-suptitle')

      this.$description = this.container.querySelector('.step .step-description')
      this.splitted = new SplitText(this.$description, { type: "words" })
      gsap.set(this.splitted.words, { opacity: 0, y: 30 })

      return gsap.to([this.$title, this.$suptitle,this.$description, ...this.splitted.words], {
        duration: .8,
        opacity: 1,
        force3D: true,
        y: 0,
        stagger: {
          amount: 0.3
        },
        ease: "power3.out",
        onComplete: () => document.addEventListener('click', this.outsideClick)
      })
    },
    outsideClick({ target }) {
      if((target && this.item && target?.id != this.item?.id) && target != this.$el && target.closest('.modal') != this.$el && this.visible && !this.background) {
        this.hide()
        this.$store.commit('global/SET_STEP_VISIBLE', false)
      }
    },
  }
}
</script>

<style lang="scss">
  .step {
    width: 100%;
    max-width: calc(100% - 80px);

    position: absolute;
    top: 50%;
    left: 50%;

    background: $white;

    text-align: center;

    transform: translateX(-50%)  translateY(calc(-50% + 10px));

    box-shadow: 0px 2px 3px rgba($black, .1), 0px 0px 24px rgba($darkgrey, 0.08);
    border-radius: 30px;

    padding: 60px 20px 20px;

    opacity: 0;
    pointer-events: none;


    transition: opacity .3s $ease-out-quad,transform .3s $ease-out-quad;
    @include media-min(m) {
      width: 500px;
    }
    .info-counter {
      margin: 40px auto;
      height: auto;
      position: inherit;
      cursor: pointer;
      .counter {
        transform: translate(0,0); 
        position: inherit;
        opacity: 1;
      }
    }

    &.visible {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(-50%)  translateY(-50%);
    }

    @include media-min(l) {
      padding: 60px;
      max-width: 500px;
    }

    &-logo {
      position: absolute;
      top: 0;
      left: 50%;
      
      width: 80px;
      height: 80px;

      transform: translateX(-50%)  translateY(-50%);

      background: $white;
      padding: 20px 22px;
      
      border: 1px solid $grey;
      border-radius: 50%;

      transition: box-shadow .3s $ease-out-quad;

      &:hover {
        box-shadow: 0px 2px 3px rgba($black, 0.1), 0px 0px 24px rgba($darkgrey, 0.08);
      }
    }

    &-suptitle {
      font-family: $primary;
      font-size: rem(15px);
      font-weight: 400;
      color: rgba($black, .5);
      letter-spacing: -0.02em;

    }

    &-title {
      font-family: $primary;
      font-size: rem(74px);
      font-weight: 300;

      
      text-align: center;
      

      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @include media-min(l) {
        height: 150px;
      }

      &.info-drag:hover {
        cursor: none;
      }
      .icon:not(.icon-step-3) {
        pointer-events: none;
        svg {
          @include media-max(s) {
            max-width: calc(100% - 80px);
          }
        }
      }
    }

    &-description {
      font-family: $primary;
      font-size: rem(15px);
      font-weight: 400;
      color: $black;
      letter-spacing: -0.02em;
      line-height: 24px;

      margin-bottom: 40px;

      @include media-max(l) {
        max-width: calc(100% - 80px);
        margin: 0 auto 40px;
      }
    }

    &-close {
      width: 25px;
      height: 25px;

      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 2;

        background: $white;

      opacity: 0;
      pointer-events: none;

      transition: opacity .3s .3s $ease-out-quad;

      svg {
        opacity: 0;
        transition: opacity .3s $ease-out-quad;
      }

      &.visible {
        opacity: 1;
        pointer-events: auto;
        transition: opacity .3s  $ease-out-quad;

        svg {
          opacity: 1;
          transition: opacity .3s .3s  $ease-out-quad;
        }
      }
    }

    .link {
      font-family: $primary;
      font-size: rem(14px);
      font-weight: 500;
      letter-spacing: -0.02em;
      color:$black;
      /* text-decoration: underline; */
      margin-top: 20px;
      transition: color .3s  $ease-out-quad;
      
      &:hover{
        color: $pink;
      }
    }
  }
</style>