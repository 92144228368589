<template>
  <canvas id="canvas"></canvas>
</template>

<script>
import gsap from 'gsap'
export default {
  name: 'Canvas',
  data() {
    return {
      ctx: null,
      pos: {
        x: 0,
        y: 0
      },
      start: {
        x: 0,
        y: 0
      },
      strokes: []
    }
  },
  mounted() {
    this.ctx = canvas.getContext('2d');
    this.ctx.canvas.width = window.APP.width;
    this.ctx.canvas.height = window.APP.height;
    this.ctx.lineCap = 'round';
    


    document.addEventListener('touchstart', this.setPosition);
    document.addEventListener('touchend', this.handleHide);
    document.addEventListener('touchmove', this.draw);
  },
  methods: {
    handleHide() {
      if( this.strokes.length === 0 ) {
        clearInterval(this.interval)
        this.interval = null
      }
      else if(!this.interval) this.interval = setInterval(this.handleInterval, 20);
    },
    handleInterval() {
      this.ctx.clearRect(0,0,canvas.width,canvas.height);
      this.createLines()
    },
    setPosition(evt) {
      const touch = evt.touches[0] || evt.targetTouches[0]
      this.pos.x = touch.clientX;
      this.pos.y = touch.clientY;
    },
    createLines() {
      for (let index = 0; index < this.strokes.length; index++) {
        const stroke = this.strokes[index];
        stroke.alpha = stroke.alpha - 0.15

        this.ctx.beginPath();

        const color = 'rgba(243,' + Math.floor(255 - 130 + stroke.x1 /window.APP.width) + ',' + Math.floor(255 - 118 +  stroke.y1 /window.APP.height) + ',' + stroke.alpha +')';
        this.ctx.strokeStyle = color
        this.ctx.lineWidth = stroke.radius;
  
        this.ctx.moveTo(stroke.x1, stroke.y1);
        this.ctx.lineTo(stroke.x2,stroke.y2);
        this.ctx.stroke();

        if(stroke.alpha <= 0) this.strokes.splice(index, 1);
      }
      if( this.strokes.length === 0 ) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    draw(evt) {
      this.ctx.clearRect(0,0, this.$el.width, this.$el.height);
      const touch = evt.touches[0] || evt.targetTouches[0]
      
      const x1 = this.pos.x
      const y1 = this.pos.y
      this.setPosition(evt);
      const x2 = this.pos.x
      const y2 = this.pos.y

      this.strokes.push( { 
        x1, 
        y1,
        x2, 
        y2,
        radius: touch.radiusX, 
        alpha: 1 
      });
      this.createLines()
    },
  }
}
</script>

<style lang="scss">
  #canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    pointer-events: none;

    width: 100vw;
    height: 100vh;
  }
</style>