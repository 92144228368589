<template>
  <section class="scene">
    <div class="scene-container">
      <div class="scene-inner">

        <Spritesheet
          v-for="index in 9" 
          :key="index" 
          :index="index" 
          :images="images" 
          @clicked="onSceneClick"
          @hovered="onSceneHover"
          @touch="onSceneTouch"
          @leave="onSceneLeave"
        />
      </div>
    </div>
  </section>
</template>

<script>
import gsap from '@/assets/_Lib/gsap.min.js'
import Draggable from '@/assets/_Lib/Draggable.min.js'
import InertiaPlugin from '@/assets/_Lib/InertiaPlugin.min.js'


import scene from '@/assets/scene.png'
// import scene from '@/assets/scene-test.png'
import sceneMobile from '@/assets/scene-mobile.png'


// DESKTOP
import phone from '@/assets/sprite/phone.png'
import phoneMobile from '@/assets/sprite/phoneMobile.png'
import remoteWomen from '@/assets/sprite/remoteWomen.png'
// // import remoteWomenMobile from '@/assets/sprite/remoteWomenMobile.png'
import remoteMen from '@/assets/sprite/remoteMen.png'
// // import remoteMenMobile from '@/assets/sprite/remoteMenMobile.png'
import yoga from '@/assets/sprite/yoga.png'
import yogaMobile from '@/assets/sprite/yogaMobile.png'
import sittingWomen from '@/assets/sprite/sittingWomen.png'
// // import sittingWomenMobile from '@/assets/sprite/sittingWomenMobile.png'
import woofwoof from '@/assets/sprite/woofwoof.png'
import woofwoofMobile from '@/assets/sprite/woofwoofMobile.png'
import remoteSleeper from '@/assets/sprite/remoteSleeper.png'
// // import remoteSleeperMobile from '@/assets/sprite/remoteSleeperMobile.png'
import sittingMen from '@/assets/sprite/sittingMen.png'
// // import sittingMenMobile from '@/assets/sprite/sittingMenMobile.png'
import remoteHello from '@/assets/sprite/remoteHello.png'
// // import remoteHelloMobile from '@/assets/sprite/remoteHelloMobile.png'
import meet from '@/assets/sprite/meet.png'
// // import meetMobile from '@/assets/sprite/meetMobile.png'
import hotdog from '@/assets/sprite/hotdog.png'
// // import hotdogMobile from '@/assets/sprite/hotdogMobile.png'
import dog from '@/assets/sprite/dog.png'
import dogMobile from '@/assets/sprite/dogMobile.png'
import burger from '@/assets/sprite/burger.png'
import burgerMobile from '@/assets/sprite/burgerMobile.png'
import covid from '@/assets/sprite/covid.png'
// // import covidMobile from '@/assets/sprite/covidMobile.png'
import map from '@/assets/sprite/map.png'
import mapMobile from '@/assets/sprite/mapMobile.png'
import paws from '@/assets/sprite/paws.png'
import pawsMobile from '@/assets/sprite/pawsMobile.png'
import tweet from '@/assets/sprite/tweet.png'
import tweetMobile from '@/assets/sprite/tweetMobile.png'
import laika from '@/assets/sprite/laika.png'
// // import laikaMobile from '@/assets/sprite/laikaMobile.png'
import computer from '@/assets/sprite/computer.png'
// // import computerMobile from '@/assets/sprite/computerMobile.png'
import plane from '@/assets/sprite/plane.png'
import planeMobile from '@/assets/sprite/planeMobile.png'
import remoteComputer from '@/assets/sprite/remoteComputer.png'
// // import remoteComputerMobile from '@/assets/sprite/remoteComputerMobile.png'
import sleepy from '@/assets/sprite/sleepy.png'
import sleepyMobile from '@/assets/sprite/sleepyMobile.png'

import yummy from '@/assets/sprite/yummy.png'
import yummyMobile from '@/assets/sprite/yummyMobile.png'

import agile from '@/assets/sprite/agile.png'
import agileMobile from '@/assets/sprite/agileMobile.png'


import Spritesheet from './SvgScene.vue'

gsap.registerPlugin(Draggable, InertiaPlugin)

export default {
  name: "Scene",
  components: { Spritesheet },
  computed: {
    // found() {
    //   return this.$store.state.global.found
    // },
    visible() {
      return this.$store.state.global.tooltip.visible
    },
    coord() {
      return this.$store.state.global.tooltip.coord
    },
    // move() {
    //   return this.$store.state.global.move
    // }
  },
  data() {
    return {
      x:0,
      y:0,
      width: 0,
      height: 0,
      speed: .4,
      proxy: null,
      images: {
        scene: window.APP.isMobile ? sceneMobile : scene,
        // yoga:  yoga,
        sittingWomen:  sittingWomen,
        // woofwoof:  woofwoof,
        remoteSleeper:  remoteSleeper,
        remoteWomen:   remoteWomen,
        sittingMen:  sittingMen,
        remoteHello:  remoteHello,
        remoteMen:  remoteMen,
        // paws:  paws,
        covid:  covid,
        // map:  map,
        // tweet:  tweet,
        // phone:  phone,
        meet:  meet,
        hotdog:  hotdog,
        // dog:  dog,
        // burger:  burger,
        laika:  laika,
        computer: computer,
        // plane: plane,
        remoteComputer: remoteComputer,
        sleepy:  window.APP.isMobile ? sleepyMobile : sleepy,
        // yummy: yummy,

        yoga: window.APP.isMobile ? yogaMobile : yoga,
        // sittingWomen: window.APP.isMobile ? sittingWomenMobile : sittingWomen,
        woofwoof: window.APP.isMobile ? woofwoofMobile : woofwoof,
        // remoteSleeper: window.APP.isMobile ? remoteSleeperMobile : remoteSleeper,
        // remoteWomen:  window.APP.isMobile ? remoteWomenMobile : remoteWomen,
        // sittingMen: window.APP.isMobile ? sittingMenMobile : sittingMen,
        // remoteHello: window.APP.isMobile ? remoteHelloMobile : remoteHello,
        // remoteMen: window.APP.isMobile ? remoteMenMobile : remoteMen,
        paws: window.APP.isMobile ? pawsMobile : paws,
        // covid: window.APP.isMobile ? covidMobile : covid,
        map: window.APP.isMobile ? mapMobile : map,
        tweet: window.APP.isMobile ? tweetMobile : tweet,
        phone: window.APP.isMobile ? phoneMobile : phone,
        // meet: window.APP.isMobile ? meetMobile : meet,
        // hotdog: window.APP.isMobile ? hotdogMobile : hotdog,
        dog: window.APP.isMobile ? dogMobile : dog,
        burger: window.APP.isMobile ? burgerMobile : burger,
        // laika: window.APP.isMobile ? laikaMobile : laika,
        // computer: window.APP.isMobile? computerMobile : computer,
        agile: window.APP.isMobile? agileMobile : agile,
        // remoteComputer: window.APP.isMobile? remoteComputerMobile : remoteComputer,
        // sleepy: window.APP.isMobile? sleepyMobile : sleepy,
        yummy: window.APP.isMobile? yummyMobile : yummy,
      }
    }
  },
  mounted() {
    window.addEventListener("load", () => {
      this.init()
    })
    window.addEventListener('resize', this.onResize)
    if(window.APP.isDesktop) window.addEventListener('wheel', this.handleScroll)
  },
  // watch: {
  //   move (value) {
  //     // if(value) {
  //     //   this.timer.play();
  //     //   this.tl.play();
  //     // }
  //     // else {
  //     //   this.timer.pause();
  //     //   this.tl.pause();
  //     // }
  //   }
  // },
  methods: {
    onSceneLeave() {
      this.$store.commit('global/SET_ID_TOOLTIP', null)
    },
    onSceneHover(id) {
      this.$store.commit('global/SET_ID_TOOLTIP', id)
      this.$store.commit('global/SET_TOOLTIP_VISIBLE', true)
    },
    onSceneTouch({target, id}) {
      if(!target || target && !id) return
      this.$store.commit('global/SET_ID_TOOLTIP', id);
      this.$store.commit('global/SET_TOOLTIP_VISIBLE', true)
      this.$store.commit('global/SET_TOOLTIP_COORD', target.getBoundingClientRect())
    },
    onSceneClick(id) {
      if(id) {
        this.$store.commit('global/SET_ID', id)
        this.$store.dispatch('global/set_found', id)
      }
    },

    handleScroll({deltaX, deltaY}) {
      this.$store.commit('global/SET_CANCLICK', false)
      if(this.visible && this.coord && window.APP.isTouchScreen) {
        this.$store.commit('global/SET_ID_TOOLTIP', null)
        this.$store.commit('global/SET_TOOLTIP_VISIBLE', false)
        this.$store.commit('global/SET_TOOLTIP_COORD', null)
      }

      this.x = -deltaX * this.speed;
      this.y = -deltaY * this.speed;

      if(this.x <= 1 && this.x >= -1 && this.y <= 1 && this.y >= -1) this.$store.commit('global/SET_CANCLICK', true)
      gsap.set(this.proxy, { x: '+=' + this.x, y: '+=' + this.y, force3D: true, });
      gsap.set(this.scenes, {
        x: (index) => gsap.utils.wrap(-this.width, this.width,  gsap.getProperty(this.proxy, "x") + this.width * (index + 1)),
        y: (index) => gsap.utils.wrap(-this.height, this.height,  gsap.getProperty(this.proxy, "y") + this.height * (index%3 + 1)),
        force3D: true,
        ease: "none"
      })
    },
    init() {
      this.scenes = document.querySelectorAll('.spritesheet')
      this.proxy = document.createElement("div");

      gsap.set(this.proxy, { x: 0, y: 0,force3D: true, });
      const that = this;

      this.update = updateProgress
      this.handleSize()
  
      this.draggable = new Draggable(this.proxy, {
        type: "x, y",
        trigger: ".scene-inner",
        inertia: {
          x: {start: 0}, 
          y: {start: 0}
        },
        dragResistance: window.APP.isMobile ? 0. : .2,
        throwProps: true,
        onDragStart: () => {
          this.$store.commit('global/SET_CANCLICK', false)
        },
        onDragEnd: () => {
          if(!window.APP.isMobile) this.$store.commit('global/SET_CANCLICK', true)
        },
        onDrag: updateProgress,
        onThrowComplete: () => {
          if(window.APP.isMobile)  this.$store.commit('global/SET_CANCLICK', true)
        },
        onThrowUpdate: updateProgress,
        ease: "power3.inout",
        snap: {     
          x: gsap.utils.snap(that.width),
          y: gsap.utils.snap(that.height),
        },
      });

      function updateProgress() {
        this.x = gsap.getProperty(that.proxy, "x")
        this.y = gsap.getProperty(that.proxy, "y")
        
        gsap.set(that.scenes, {
          force3D: true,
          x: (index) => gsap.utils.wrap(-that.width, that.width, this.x + that.width * (index + 1)),
          y: (index) => gsap.utils.wrap(-that.height, that.height, this.y + that.height * (index%3 + 1)),
          ease: "none"
        })

        if(that.visible && that.coord && window.APP.isTouchScreen) {
          that.$store.commit('global/SET_ID_TOOLTIP', null)
          that.$store.commit('global/SET_TOOLTIP_VISIBLE', false)
          that.$store.commit('global/SET_TOOLTIP_COORD', null)
        }
      }
    },
    
    onResize() {
      this.handleSize()
    },
    handleSize() {
      const img = this.scenes[0].querySelector('img')
      this.width = window.innerWidth
      this.height = (img.naturalHeight / img.naturalWidth) * window.innerWidth

      if(window.innerWidth < window.innerHeight || window.innerHeight > this.height) {
        this.width = (window.innerWidth / this.height) *  window.APP.height
        this.height = window.APP.height
      }
      if(window.APP.isMobile) {
        this.width = this.width * 2 
        this.height = this.height * 2 
      }
      this.$el.style.width = `${this.width * 3}px`
      this.$el.style.height = `${this.height * 3}px`

      this.scenes.forEach(scene => {
        scene.style.width = `${this.width}px`
        scene.style.height = `${this.height}px`
      })
      this.wrapWidth = this.width * 3;
      this.wrapHeight = this.height * 3;

      this.update()
    }
    
  }
}
</script>

<style lang="scss">
  .scene {
    &-container {
      height: 100%;
      width: 100%;  
    }
    &-inner {
      position: relative;
      height: 100%;
      width: 100%;  
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
    }
    .spritesheet {
      position: absolute;

    }
  }
</style>