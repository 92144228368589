<template>
  <div
    class="group"
    :class="{noPointer: noPointer}"
    :data-id="fact"
    :style="'width:'+w+'%; height:'+h+'%; left:' + trX +'%; top:' + trY +'%; transform:' +rotate "
  >
  </div>
</template>


<script>
export default {
  name: "Hover",
  props: {
    fact: {
      type: String
    },
    x: {
      type: Number
    },
    y: {
      type: Number
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    steps: {
      type: Number
    },
    rotate: {
      type: String,
    },
  },
  computed: {
    canClick() {
      return this.$store.state.global.canClick
    },
  },
  data() {
    return {
      noPointer: false,
      w:0,
      h:0,
      trX:0,
      trY:0,
    }
  },
  watch: {
    canClick(value) {
      this.noPointer = value ? false : true
      return value
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    if(this.w === 0) window.addEventListener("load", this.handleSize)
  },
  methods: {
    handleSize() {
      this.w = (this.width / 4096 * this.$el.parentNode.clientWidth)  / window.innerWidth * 100
      this.h = (this.height / 3965 * this.$el.parentNode.clientWidth )  / window.innerWidth * 100
      this.trX = (this.x / 4096 * this.$el.parentNode.clientWidth) / window.innerWidth * 100
      this.trY = (this.y / 3965 * this.$el.parentNode.clientWidth  ) / window.innerWidth * 100
    }
  }
}
</script>

<style lang="scss">
  .group {
    position: absolute;
  }
</style>