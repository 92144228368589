<template>
  <div 
    class="loading"
  > 
    <div class="loading-background" ></div> 
    <div class="loading-image"  ref="image" >
      <div class="loading-image-container">
        <img 
          width="200" height="200" 
          :src="image"
          alt="Animation d'un chien volant"
        />
      </div>
    </div>
    <strong ref="progress" class="loading-progress">
      <span >{{ progress }}</span>
      %
    </strong>
  </div>
</template>

<script>
import gsap from '@/assets/_Lib/gsap.min.js'
import LoadingImage from '@/assets/sprite/loading.png'
import LoadingImageMobile from '@/assets/sprite/loadingMobile.png'

export default {
  name: "Loading",
  data() {
    return {
      speed: 20,
      progress: 0,
      date: Date.now(), 
      image:window.APP.isMobile ? LoadingImageMobile : LoadingImage ,
      // imageMobile: LoadingImage,
      interval: null,
    }
  },
  mounted() {
    const fn = this.onLoad
    gsap.to([this.$refs.image, this.$refs.progress], {
      opacity: 1,
      y: 0,
      duration: .8,
      delay: .8,
      // stagger: 0.8,
      force3D: true,
      ease: "power3.out",
      onComplete: this.init(fn)
    })
    
  },
  methods: {
    onLoad(image, index) {
      return image.onLoad = () => {
        this.limit = Number(((index/length) * 100).toFixed(0))
        clearInterval(this.interval)

        if(this.progress <= this.limit && this.progress < 100) this.interval = setInterval(this.updateProgress, this.speed);
      }
    },
    init(fn) {
      const images = document.querySelectorAll('img')
      images.forEach( (image, index) => fn(image, index, images.length) )

      window.addEventListener("load", () => {
        this.limit = 100
        this.interval = setInterval(this.updateProgress,  this.speed);
      });
    },
    updateProgress() {
      const newDate = Date.now();
      const milliseconds = newDate - this.date;

      const seconds = Math.floor(milliseconds / 1000);
      this.progress = Number(this.progress) + seconds <= 100 ? Number(this.progress) + seconds : 100;
      if(this.progress === 100) {
        clearInterval(this.interval)
        this.onLoaded()
      }
    },
    onLoaded() {
      gsap.to([this.$refs.image, this.$refs.progress], {
        opacity: 0,
        duration: .8,
        // stagger: 0.8,
        delay: .2,
        force3D: true,
        ease: "power3.out",
        onStart: () => {
          this.$el.classList.add('loaded');
        },
        onComplete: () => {
          this.$store.commit('global/SET_ID', 'step-1');
          this.$store.commit('global/SET_MOVE', true);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .loading {
    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;

    display: flex;
    justify-content: center;
    align-items: center;

    &.loaded {
      pointer-events: none;
    }
    
    &-background {
      position: absolute;
      top: 0%;
      left: 0%;

      background: $white;
      width: 100vw;
      height: 100vh;
      opacity: 1;

      transition:  transform .6s .4s $ease-out-quad, opacity .6s .4s $ease-out-quad;

      .loaded & {
        opacity: 0;
      }
    }

    &-image {
      width: 200px;
      height: 200px;
      
      opacity: 0;
      transform: translateY(10px);

      &-container {
        width: 200px;
        height: 200px;
        overflow: hidden;
        transform: scaleX(-1) rotate(-20deg) translateX(-10px) translateY(-80px);
      }
      img {
        width:2000px;
        height: 100%;
        animation: sprite steps(10) .8s infinite;
      }
    }
    @keyframes sprite {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }

    &-progress {
      position: relative;
      z-index: 1;
      width: 78px;
      font-size: rem(20px);
      font-weight: 400;
      
      opacity: 0;
      transform: translateY(-10px);
      
      span {
        font-size: rem(30px);
      }
    }
  }
</style>