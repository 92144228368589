<template>
  <div class="tooltip" :class="{visible}">
    <svg ref="arrow" class="tooltip-arrow" width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 16.026C15.4 2.42603 4.66667 -0.307297 0 0.0260361C11.2 6.82616 7.33333 16.8593 4 21.0259L21 16.026Z" fill="#FCA79C"/>
      <path d="M21 16.026C15.4 2.42603 4.66667 -0.307297 0 0.0260361C11.2 6.82616 7.33333 16.8593 4 21.0259L21 16.026Z" fill="white" fill-opacity="0.7"/>
    </svg>

    <p v-html="tooltip?.title"/>
  </div>
</template>

<script>
import gsap from '@/assets/_Lib/gsap.min.js'
export default {
  name: "Tooltip",
  computed: {
    tooltip() {
      return this.$store.state.global.tooltip.content
    },
    id() {
      return this.$store.state.global.tooltip.id
    },
    visible() {
      return this.$store.state.global.tooltip.visible
    },
    coord() {
      return this.$store.state.global.tooltip.coord
    }
  },
  watch: {
    async id(value) {
      if(value) {
        await this.$store.commit('global/SET_TOOLTIP', value)
        this.$store.commit('global/SET_TOOLTIP_VISIBLE', true)
        gsap.to(this.$el, {
          opacity:1, 
          duration: .07,
        })
      }
      else {
        gsap.to(this.$el, {
          opacity:0, 
          duration: .07,
          onComplete: () => {
            this.$store.commit('global/SET_TOOLTIP_VISIBLE', false)
          }
        })
      }
    },
    visible(value) {
      if(value) window.addEventListener('mousemove', this.handleMove)
      else window.removeEventListener('mousemove', this.handleMove)
    },
    async coord(coord) {
      await this.$store.commit('global/SET_TOOLTIP', this.id)
      if(coord) this.handleTouch({x: coord.x + coord.width / 2, y: coord.bottom})
    }
  },
  methods: {
    handleTouch({x, y}) {
      const {width, height} = this.$el.getBoundingClientRect()
      x = x - width/2
      gsap.to(this.$el, {
        x: x,
        y: y - height/2 + 20,
        force3D: true,
      })
    },
    handleMove(evt) {
      const {clientX, clientY} = evt
      const {width, height} = this.$el.getBoundingClientRect()

      const x = clientX - width/2
      gsap.to(this.$el, {
        x: x,
        y: clientY - height/2 + 20,
      })
    }
  }
}
</script>

<style lang="scss">
  .tooltip {
    position: absolute;
    top: 0;
    left: 0;

    background: $lightpink;
    padding: 15px 30px;

    font-size: rem(16px);
    letter-spacing: -0.02em;

    border-radius: 30px;

    opacity: 0;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.119509)) drop-shadow(0px 0px 24px rgba(74, 74, 87, 0.08));
    pointer-events: none;

    transition: opacity .3s $ease-out-quad;
  
    &.visible {
      opacity: 1;
    }

    &-arrow {
      display: block;
      position: absolute;
      top: -10px;
      left: 50%;

      /* transform: translateX(-50%); */
    }
  }
</style>