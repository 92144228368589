<template>
  <div 
    class="song"
    :class="{isPlay}"
  >
    <button class="song-button" type="button" @click="onClick">
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g><rect x="0" y="0" width="2" height="19"/></g>
        <g><rect x="4" y="0" width="2" height="19"/></g>
        <g><rect x="8" y="0" width="2" height="19"/></g>
        <g><rect x="12" y="0" width="2" height="19"/></g>
        <g><rect x="16" y="0" width="2" height="19"/></g>
      </svg>
      <span class="u-visually-hidden">Couper/jouer la musique</span>
    </button>
  </div>
</template>

<script>
import gsap from '@/assets/_Lib/gsap.min.js'

import AudioSong from '@/assets/audio/audio.mp3'

export default {
  name:"Audio",
  computed: {
    isPlay() {
      return this.$store.state.global.audio
    },
    isHide() {
      return this.$store.state.global.hide
    }
  },
  watch: {
    isPlay() {
      if(this.isPlay) this.handlePlay()
      else this.handlePause()
    }
  },
  mounted() {
    // this.$audio = this.$refs.audio 
    this.$audio = new Audio(AudioSong);
    this.$audio.loop = true
    
    this.$audio.volume = .5
    this.rects = this.$el.querySelectorAll('g')
    this.tl = gsap.timeline({
      repeat: -1,
      yoyo: true,
      repeatRefresh: true,
      paused: true
    })

    this.tl.to(this.rects, {
      duration: .3,
      transformOrigin: "center",
      // ease: "linear",
      // stagger: {
      //   amount: 0.1,
      //   from: "center"
      // },
      yoyo:true,
      ease: "quad.inOut",
      repeatRefresh: true,
      scaleY: (index) => {
        return gsap.utils.random(.7, 3)
      },
      force3D: true
    })

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "visible") {
        if(this.isPlay) this.handlePlay()
      } else {
        if(this.isPlay) this.handlePause()
      }
    });
    
  },
  methods: {
    handlePlay() {
      this.$audio.play()
      this.tl.restart()
    },
    handlePause() {
      this.$audio.pause()
      this.tl.pause()
    },
    onClick() {
      this.$store.commit('global/UPDATE_AUDIO', !this.isPlay)
    }
  }
}
</script>

<style lang="scss">
  .song {
    /* position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 11; */

    @include media-max(l) {
      position: absolute;
      bottom: 20px;
      right: 20px;
      z-index:0;

    }
    @include media-min(m) {
      bottom: 40px;
      right: 40px;
    }

    &-button {
      width: 60px;
      height: 60px;

      box-shadow: 0px 2px 3px rgba($black, .12);
      background: $white;

      border-radius: 50%;

      font-family: $primary;
      font-weight: 600;
      font-size: rem(16)
    }
    &.isPlay rect {
      fill: $pink;
    }
    rect {
      fill: $black;
      transform-origin: center;
      transform: scaleY(0.3);
      
      
      /* animation: playin .9s infinite $ease-out-quad; */
    }

    /* @keyframes playin {
      0% {
        transform: scaleY(0.3);
      }
      20% {
        transform: scaleY(0.1);
      }
      40% {
        transform: scaleY(0.7);
      }
      100% {
        transform: scaleY(1);
      }
    } */
  }
</style>