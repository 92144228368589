import { createApp } from 'vue'

import '@/assets/js/window'
import '@/assets/js/console'

import App from './App.vue'


// import router from './core/router';
import store from './core/store';




const app = createApp(App)
      // app.use(router)
      app.use(store)
      app.mount('#app')