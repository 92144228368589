<template>
  <div
     class="group"
    :class="{noPointer: noPointer}"
    :data-id="fact"
    :style="'width:'+w+'%; height:'+h+'%; left:' + trX +'%; top:' + trY +'%; transform:' +rotate "
  >
    <picture>
      <img 
         ref="image"
        :src="img" 
        :alt="'illustration of'+ name "
        :width="width * steps" 
        :height="height"
      />
    </picture>
  </div>
</template>


<script>
import gsap from '@/assets/_Lib/gsap.min.js'

export default {
  name: "Sprite",
  props: {
    img: {
      type: String
    },
    name: {
      type: String
    },
    fact: {
      type: String
    },
    x: {
      type: Number
    },
    y: {
      type: Number
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    steps: {
      type: Number
    },
    rotate: {
      type: String,
    },
    canplay: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    canClick() {
      return this.$store.state.global.canClick
    },
  },
  data() {
    return {
      noPointer: false,
      w:0,
      h:0,
      trX:0,
      trY:0,
    }
  },
  watch: {
    canplay(value) {
      if(!value) {
        this.tl.pause()
        this.noPointer = true
      }
    },
    canClick(value) {
      // if(value && canplay) {
      //   this.tl.play()
      //   this.noPointer = false
      // }
      // else {
      //   this.tl.pause()
      //   this.noPointer = true
      // }
      // return value
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    if(this.w === 0 && !this.tl) {
      window.addEventListener("load", () => {
         this.handleSize()
          this.tl = gsap.to(this.$refs.image, {
            repeat: -1, 
            repeatDelay: window.APP.isMobile ? gsap.utils.random(1., 3.) : gsap.utils.random(1., 7.), 
            duration:1/10 * this.steps,
            force3D: true,
            ease: `steps(${this.steps - 1})`,
            xPercent: -100 + (1 / (this.steps) * 100),
            paused: !this.canplay
          })
      })
    }
  },
  methods: {
    handleSize() {
      this.w = ((this.width - 2) / 4096 * this.$el.parentNode.clientWidth)  / window.innerWidth * 100
      this.h = (this.height / 3965 * this.$el.parentNode.clientWidth )  / window.innerWidth * 100
      this.trX = (this.x / 4096 * this.$el.parentNode.clientWidth) / window.innerWidth * 100
      this.trY = (this.y / 3965 * this.$el.parentNode.clientWidth  ) / window.innerWidth * 100
    }
  }
}
</script>

<style lang="scss">
  .group {
    position: absolute;
    overflow: hidden;
    picture {
      display: block;
      position: absolute;
      top:0 ;
      left: -0.5px;
      height: 100%;
      backface-visibility: hidden;
    }
    img {
      width: auto;
      display: block;
      height: 100%;
    }
  }
</style>