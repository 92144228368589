function initWindow() {
  window.APP = {
    isMobile: window.innerWidth < 980 || window.innerWidth < 980 && "ontouchstart" in window,
    isTablet: window.innerWidth >= 980 && window.innerWidth < 1024 && "ontouchstart" in window,
    isDesktop: !("ontouchstart" in window) || window.innerWidth >= 1024,
    isTouchScreen: "ontouchstart" in window ,
    width: window.innerWidth,
    height: window.innerHeight,
    portrait: window.innerWidth > window.innerHeight
  }
}

initWindow()
window.addEventListener('resize', initWindow)