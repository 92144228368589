<template>
  <button 
    v-if="button?.text"
    type="button"
    class="btn"
    :class="[button.class, {clicked}]"
    @click="clicked = true"
  > 
    <span class="btn-text">
      {{button.text}}
    </span>
    <span ref="arrow" class="btn-arrow">
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.851563 9.49658L13.0216 9.49658L7.43156 15.0866L8.85156 16.4966L16.8516 8.49658L8.85156 0.496581L7.44156 1.90658L13.0216 7.49658L0.851563 7.49658L0.851563 9.49658Z" fill="white"/>
      </svg>
    </span>
  </button>
  <div v-else-if="link">
    <a  
      class="btn"
      :class="link.class" 
      rel="noopener" 
      target="_blank" 
      :href="link.url"
      @click="clicked=true"
    >
      {{link.text}}
      <span class="btn-arrow">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.851563 9.49658L13.0216 9.49658L7.43156 15.0866L8.85156 16.4966L16.8516 8.49658L8.85156 0.496581L7.44156 1.90658L13.0216 7.49658L0.851563 7.49658L0.851563 9.49658Z" fill="white"/>
        </svg>
      </span>
    </a>
    <button v-if="!link.noLate" class="link" @click="onClose">Plus tard</button>
  </div>
  <button 
    v-else 
    class="btn btn--small" 
    type="button"
    :class="{clicked}"
    @click="clicked=true"
  >
    <span class="btn-arrow" v-if="button?.id">
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.851563 9.49658L13.0216 9.49658L7.43156 15.0866L8.85156 16.4966L16.8516 8.49658L8.85156 0.496581L7.44156 1.90658L13.0216 7.49658L0.851563 7.49658L0.851563 9.49658Z" fill="white"/>
      </svg>
    </span>
    <span class="btn-cross" v-else>
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94971 6.36415L0.000150681 11.3137L1.41436 12.7279L6.36392 7.77837L11.3137 12.7282L12.7279 11.3139L7.77813 6.36415L12.7281 1.41421L11.3139 0L6.36392 4.94994L1.41421 0.000232697L0 1.41445L4.94971 6.36415Z" fill="white"/>
      </svg>
    </span>
  </button>

</template>

<script>
export default {
  name: "Btn",
  props: {
    button: {
      type: [Object, String]
    },
    link: {
      type: [Object, String]
    }
  },
  watch: {
    button() {
      this.clicked = false
    },
    link() {
      this.clicked = false
    },
  },
  data() {
    return {
      clicked: false
    }
  }
}
</script>

<style lang="scss">
  .link {
    font-family: $primary;
    font-size: rem(14px);
    font-weight: 500;
    letter-spacing: -0.02em;
    color:$black;
    /* text-decoration: underline; */
    margin-top: 20px;
    transition: color .3s  $ease-out-quad;
    
    &:hover{
      color: $pink;
    }
  }

  .btn {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    min-width: 265px;
    width: fit-content;

    padding: 10px 10px 10px 30px;
    margin: 0 auto;

    background: $white;

    border-radius: 120px;

    font-family: $primary;
    font-size: rem(16px);
    font-weight: 600;
    color: $black;
    letter-spacing: -0.02em;
    overflow: hidden;

    box-shadow: 0px 2px 3px rgba($black, .1), 0px 0px 24px rgba($darkgrey, 0.08);
    transition: opacity .3s $ease-out-quad, box-shadow .3s $ease-out-quad;
    &--small {
      width: auto;
      min-width: 0;
      padding: 10px;


      .btn-arrow {
        margin-left: 0;
      }
    }

    &-text {
      transform: translateX(0);
      opacity: 1;
      transition: transform .3s $ease-out-quad, opacity .3s $ease-out-quad, color .3s $ease-out-quad;
    }

    &-purple {
      margin-bottom: 20px;
    }
    &:hover {
      box-shadow: 0px 2px 3px rgba($black, 0.05), 0px 0px 24px rgba($darkgrey, 0.08);
      .btn-arrow::after, .btn-cross::after {
        transform: scale(1.2);
        box-shadow: 0px 2px 3px rgba($black, 0.1), 0px 0px 24px rgba($darkgrey, 0.08);
      }
    }
    
    &.clicked {
      opacity: 0;
      .btn-text {
        color: $white;
        transform: translateX(-10px);
        opacity: 0;
      }
      .btn-arrow::after {
        transform: scale(.8);
        box-shadow: 0px 2px 3px rgba($black, 0), 0px 0px 24px rgba($darkgrey, 0);
      }
    }

    
    &-arrow, &-cross {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 45px;
      height: 45px;

      background: $pink;

      border-radius: 50%;
      z-index: 1;

      .btn-purple & {
        background: $purple;
      }
      
      &:after {
        content: '';

        position: absolute;
        top: 0%;
        right: 0px;
        width: 45px;
        height: 45px;

        background: $pink;

        border-radius: 100px;
        z-index: -1;

        box-shadow: 0px 2px 3px rgba($black, 0), 0px 0px 24px rgba($darkgrey, 0);

        transition: transform .3s $ease-out-quad, box-shadow .3s $ease-out-quad;

        .btn-purple & {
          background: $purple;
        }
      }
    }

    &-arrow {
       margin-left: 20px;
    }


  }
</style>