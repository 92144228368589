<template>
  <div class="spritesheet">
      <img 
        width="3000"
        height="2905"
        class="spritesheet-img"
        :src="images?.scene" 
        alt="Happy new year"
      />
    <div class="spritesheet-images?" :id="'spritesheet'+index"  viewBox="0 0 4096 3965" fill="none" >
       <!-- Burger -->
       <Sprite 
        :class="{clicked: this.found.indexOf('fact-1') !== -1}"
        :canplay="this.found.indexOf('fact-1') === -1"
        @click="() => onClick('fact-1')"
        @touchstart="() => onClickMobile('fact-1')"
        :img="images?.burger"
        :name="'burger'"
        :fact="'fact-1'"
        :x="695"
        :y="587"
        :width="380"
        :height="304"
        :steps="17"
      />

      <!-- Remote Men -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-2') !== -1}"
        :canplay="this.found.indexOf('fact-2') === -1"
        @click="() => onClick('fact-2')"
        @touchstart="() => onClickMobile('fact-2')"
        :img="images?.remoteMen"
        :name="'remoteMen'"
        :fact="'fact-2'"
        :x="950.4"
        :y="1654.65"
        :width="463.41"
        :height="532.58"
        :steps="9"
        :rotate="'rotate(15deg)'"
      />

       <!-- Phone -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-3') !== -1}"
        :canplay="this.found.indexOf('fact-3') === -1"
        @click="() => onClick('fact-3')"
        @touchstart="() => onClickMobile('fact-3')"
        :img="images?.phone"
        :name="'phone'"
        :fact="'fact-3'"
        :x="3130"
        :y="2920"
        :width="630"
        :height="448"
        :steps="16"
      />

      <!-- Laika -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-4') !== -1}"
        :canplay="this.found.indexOf('fact-4') === -1"
        @click="() => onClick('fact-4')"
        @touchstart="() => onClickMobile('fact-4')"
        :img="images?.laika"
        :name="'laika'"
        :fact="'fact-4'"
        :x="780"
        :y="12"
        :width="448"
        :height="448"
        :steps="10"
      />

      <!-- Woof -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-5') !== -1}"
        :canplay="this.found.indexOf('fact-5') === -1"
        @click="() => onClick('fact-5')"
        @touchstart="() => onClickMobile('fact-5')"
        :img="images?.woofwoof"
        :name="'woofwoof'"
        :fact="'fact-5'"
        :x="2451"
        :y="1620"
        :width="572"
        :height="414"
        :steps="9"
      />

      <!-- Map -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-6') !== -1}"
        :canplay="this.found.indexOf('fact-6') === -1"
        @click="() => onClick('fact-6')"
        @touchstart="() => onClickMobile('fact-6')"
        :img="images?.map"
        :name="'map'"
        :fact="'fact-6'"
        :x="1932"
        :y="2350"
        :width="690"
        :height="628"
        :steps="13"
      />

      <!-- Tweet -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-7') !== -1}"
        :canplay="this.found.indexOf('fact-7') === -1"
        @click="() => onClick('fact-7')"
        @touchstart="() => onClickMobile('fact-7')"
        :img="images?.tweet"
        :name="'tweet'"
        :fact="'fact-7'"
        :x="1479"
        :y="2312"
        :width="446"
        :height="335"
        :steps="23"
      />

      <!-- Meet -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-8') !== -1}"
        :canplay="this.found.indexOf('fact-8') === -1"
        @click="() => onClick('fact-8')"
        @touchstart="() => onClickMobile('fact-8')"
        :img="images?.meet"
        :name="'meet'"
        :fact="'fact-8'"
        :x="3429.56"
        :y="465"
        :width="477.23"
        :height="441.46"
        :steps="21"
        :rotate="'rotate(12.8093deg)'"
      />

      <!-- Hotdog -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-9') !== -1}"
        :canplay="this.found.indexOf('fact-9') === -1"
        @click="() => onClick('fact-9')"
        @touchstart="() => onClickMobile('fact-9')"
        :img="images?.hotdog"
        :name="'hotdog'"
        :fact="'fact-9'"
        :x="2224"
        :y="1975"
        :width="162"
        :height="237"
        :steps="19"
      />

      <!-- Sitting Women -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-10') !== -1}"
        :canplay="this.found.indexOf('fact-10') === -1"
        @click="() => onClick('fact-10')"
        @touchstart="() => onClickMobile('fact-10')"
        :img="images?.sittingWomen"
        :name="'sittingWomen'"
        :fact="'fact-10'"
        :x="1412"
        :y="3370"
        :width="520"
        :height="519"
        :steps="21"
      />

      <!-- Dog -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-11') !== -1}"
        :canplay="this.found.indexOf('fact-11') === -1"
        @click="() => onClick('fact-11')"
        @touchstart="() => onClickMobile('fact-11')"
        :img="images?.dog"
        :name="'dog'"
        :fact="'fact-11'"
        :x="2813"
        :y="2898"
        :width="249"
        :height="270"
        :steps="20"
      />

      <!-- Remote Women -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-12') !== -1}"
        :canplay="this.found.indexOf('fact-12') === -1"
        @click="() => onClick('fact-12')"
        @touchstart="() => onClickMobile('fact-12')"
        :img="images?.remoteWomen"
        :name="'remoteWomen'"
        :fact="'fact-12'"
        :x="1617"
        :y="1737"
        :width="466"
        :height="433"
        :steps="35"
      />

      <!-- Computer -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-13') !== -1}"
        :canplay="this.found.indexOf('fact-13') === -1"
        @click="() => onClick('fact-13')"
        @touchstart="() => onClickMobile('fact-13')"
        :img="images?.computer"
        :name="'computer'"
        :fact="'fact-13'"
        :x="1787"
        :y="115"
        :width="632"
        :height="421"
        :steps="21"
      />

      <!-- Agile -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-14') !== -1}"
        :canplay="this.found.indexOf('fact-14') === -1"
        @click="() => onClick('fact-14')"
        @touchstart="() => onClickMobile('fact-14')"
        :img="images?.agile"
        :name="'agile'"
        :fact="'fact-14'"
        :x="319"
        :y="3406"
        :width="537"
        :height="520"
        :steps="24"
      />

       <!-- Yoga -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-15') !== -1}"
        :canplay="this.found.indexOf('fact-15') === -1"
        @click="() => onClick('fact-15')"
        @touchstart="() => onClickMobile('fact-15')"
        :img="images?.yoga"
        :name="'yoga'"
        :fact="'fact-15'"
        :x="1360"
        :y="411"
        :width="531"
        :height="462"
        :steps="18"
      />

      <!-- Sitting Men -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-16') !== -1}"
        :canplay="this.found.indexOf('fact-16') === -1"
        @click="() => onClick('fact-16')"
        @touchstart="() => onClickMobile('fact-16')"
        :img="images?.sittingMen"
        :name="'sittingMen'"
        :fact="'fact-16'"
        :x="1965"
        :y="1100.65"
        :width="362.17"
        :height="509.3"
        :steps="11"
        :rotate="'rotate(14.5195deg)'"
      />

      <!-- Remote Hello -->
       <Sprite 
        :class="{clicked: this.found.indexOf('fact-17') !== -1}"
        :canplay="this.found.indexOf('fact-17') === -1"
        @click="() => onClick('fact-17')"
        @touchstart="() => onClickMobile('fact-17')"
        :img="images?.remoteHello"
        :name="'remoteHello'"
        :fact="'fact-17'"
        :x="2575"
        :y="960.79"
        :width="571.24"
        :height="465.67"
        :steps="15"
        :rotate="'rotate(-12.2882deg)'"
      />

      <!-- Covid -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-18') !== -1}"
        :canplay="this.found.indexOf('fact-18') === -1"
        @click="() => onClick('fact-18')"
        @touchstart="() => onClickMobile('fact-18')"
        :img="images?.covid"
        :name="'covid'"
        :fact="'fact-18'"
        :x="2054"
        :y="3188"
        :width="352"
        :height="571"
        :steps="21"
      />

      <!-- RemoteComputer -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-19') !== -1}"
        :canplay="this.found.indexOf('fact-19') === -1"
        @click="() => onClick('fact-19')"
        @touchstart="() => onClickMobile('fact-19')"
        :img="images?.remoteComputer"
        :name="'remoteComputer'"
        :fact="'fact-19'"
        :x="156"
        :y="159"
        :width="453"
        :height="362"
        :steps="26"
      />

      <!-- Sleepy -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-20') !== -1}"
        :canplay="this.found.indexOf('fact-20') === -1"
        @click="() => onClick('fact-20')"
        @touchstart="() => onClickMobile('fact-20')"
        :img="images?.sleepy"
        :name="'sleepy'"
        :fact="'fact-20'"
        :x="3318"
        :y="207"
        :width="285.034"
        :height="252"
        :steps="6"
      />

      <!-- Remote Sleeper -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-21') !== -1}"
        :canplay="this.found.indexOf('fact-21') === -1"
        @click="() => onClick('fact-21')"
        @touchstart="() => onClickMobile('fact-21')"
        :img="images?.remoteSleeper"
        :name="'remoteSleeper'"
        :fact="'fact-21'"
        :x="3163"
        :y="1317"
        :width="536"
        :height="638"
        :steps="35"
      />
      
      <!-- Yummy -->
      <Sprite 
        :class="{clicked: this.found.indexOf('fact-22') !== -1}"
        :canplay="this.found.indexOf('fact-22') === -1"
        @click="() => onClick('fact-22')"
        @touchstart="() => onClickMobile('fact-22')"
        :img="images?.yummy"
        :name="'yummy'"
        :fact="'fact-22'"
        :x="3327"
        :y="1922"
        :width="682"
        :height="380"
        :steps="23"
      />

      <Hover 
        @touchstart="(evt) => this.$emit('touch',  {target: evt.target, id:'hover-1'}) "
        @mouseenter="this.$emit('hovered', 'hover-1')"
        @mouseleave="this.$emit('leave')"

        :fact="'hover-1'"
        :x="1584"
        :y="961"
        :width="325"
        :height="421"
      />
      <Hover 
        @touchstart="(evt) => this.$emit('touch',  {target: evt.target, id:'hover-2'}) "
        @mouseenter="this.$emit('hovered', 'hover-2')"
        @mouseleave="this.$emit('leave')"

        :fact="'hover-2'"
        :x="2510"
        :y="2093"
        :width="246"
        :height="212"
      />
      <Hover 
        @touchstart="(evt) => this.$emit('touch',  {target: evt.target, id:'hover-3'}) "
        @mouseenter="this.$emit('hovered', 'hover-3')"
        @mouseleave="this.$emit('leave')"

        :fact="'hover-3'"
        :x="1276"
        :y="2789"
        :width="443"
        :height="443"
      />
      <Hover 
        @touchstart="(evt) => this.$emit('touch',  {target: evt.target, id:'hover-4'}) "
        @mouseenter="this.$emit('hovered', 'hover-4')"
        @mouseleave="this.$emit('leave')"

        :fact="'hover-4'"
        :x="29"
        :y="2311"
        :width="533"
        :height="353"
      />
      <Hover 
        @touchstart="(evt) => this.$emit('touch',  {target: evt.target, id:'hover-5'}) "
        @mouseenter="this.$emit('hovered', 'hover-5')"
        @mouseleave="this.$emit('leave')"

        :fact="'hover-5'"
        :x="601"
        :y="1382"
        :width="471"
        :height="206"
      />
      <Hover 
        @touchstart="(evt) => this.$emit('touch',  {target: evt.target, id:'hover-6'}) "
        @mouseenter="this.$emit('hovered', 'hover-6')"
        @mouseleave="this.$emit('leave')"

        :fact="'hover-6'"
        :x="94"
        :y="1926"
        :width="244"
        :height="245"
      />
      <Hover 
        @touchstart="(evt) => this.$emit('touch',  {target: evt.target, id:'hover-7'}) "
        @mouseenter="this.$emit('hovered', 'hover-7')"
        @mouseleave="this.$emit('leave')"

        :fact="'hover-7'"
        :x="2613"
        :y="15"
        :width="633"
        :height="693"
      />
      <Hover 
        @touchstart="(evt) => this.$emit('touch',  {target: evt.target, id:'hover-8'}) "
        @mouseenter="this.$emit('hovered', 'hover-8')"
        @mouseleave="this.$emit('leave')"

        :fact="'hover-8'"
        :x="2156"
        :y="612"
        :width="230"
        :height="236"
      />
      <Hover 
        @touchstart="(evt) => this.$emit('touch',  {target: evt.target, id:'hover-9'}) "
        @mouseenter="this.$emit('hovered', 'hover-9')"
        @mouseleave="this.$emit('leave')"

        :fact="'hover-9'"
        :x="3266"
        :y="2302"
        :width="180"
        :height="252"
      />
      <Hover 
        @touchstart="(evt) => this.$emit('touch', {target: evt.target, id:'hover-10'}) "
        @mouseenter="this.$emit('hovered', 'hover-10')"
        @mouseleave="this.$emit('leave')"

        :fact="'hover-10'"
        :x="405"
        :y="1054"
        :width="277"
        :height="211"
      />
      <Hover 
        @touchstart="(evt) => this.$emit('touch',  {target: evt.target, id:'hover-11'}) "
        @mouseenter="this.$emit('hovered', 'hover-11')"
        @mouseleave="this.$emit('leave')"

        :fact="'hover-11'"
        :x="1069"
        :y="3429"
        :width="331"
        :height="261"
      />
    </div>
  </div>
</template>

<script>
import gsap from '@/assets/_Lib/gsap.min.js'
import CustomEase from '@/assets/_Lib/CustomEase.min.js'
import CustomWiggle from '@/assets/_Lib/CustomWiggle.min.js'
gsap.registerPlugin(CustomEase, CustomWiggle);


import Sprite from './Sprite.vue';
import Hover from './Hover.vue';

export default {
  name:'Spritesheet',
  components: { Sprite, Hover },
  props: {
    index: {
      type: Number,
    },
    images: {
      type: Object,
      required: true
    }
  },
  computed: {
    canClick() {
      return this.$store.state.global.canClick
    },
    found() {
      return this.$store.state.global.found
    },
    counter() {
      return this.$store.state.global.counter
    }
  },

  watch: {
    counter(value) {
      if(this.animation) this.animation.kill()
      if(value >= 15) {
        setTimeout(() => {
          const $els = this.$el.querySelectorAll(".group:not(.clicked)")
          this.handleRotation($els)
        }, 10)
      }
    }
  },
  mounted() {
    },
  methods: {
    handleRotation($els) {
      CustomWiggle.create("Wiggle.easeOut", {wiggles:6, type:"easeOut"})
      this.animation = gsap.to($els, {
        repeat: -1, 
        repeatDelay: gsap.utils.random(10, 20), 
        delay: gsap.utils.random(10, 20), 
        force3D: true,
        duration:.8, 
        rotation:7,
        stagger: {
          amount: 4,
          from: "random",
        },
        transformOrigin: "center",
        ease: "Wiggle.easeOut",
      })
    },
    onClick(id) {
      if(this.canClick) this.$emit('clicked', id);
    },
    onClickMobile(id) {
      if(window.APP.isTouchScreen && this.canClick) this.$emit('clicked', id)
    },
    
  }
}
</script>

<style lang="scss">
  .spritesheet {
    background: $white;
    max-width: 100%;
    will-change: transform;

    display: block;
    .clicked, .noPointer {
      pointer-events: none;
    }
    image {
      pointer-events: none;
    }
    .group {
      /* overflow: hidden; */
      transition: opacity .2s $ease-out-quad;
    }
    .clicked {
      pointer-events: none;
      picture {
        opacity: .3;
            
      }
    }
    &-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
    }

    svg:not(.noPointer), .side, &-images {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }
</style>