<template>
  <div id="app-container">
    <main>
      <Loading />
      <Scene />
      <div class="app-background" :class="{visible:background}"></div>
      <div class="app-overlay" :class="{visible:overlay}"></div>
      
      <Info :class="{isVisible:!background}"  />
      <Party :class="{isVisible:!background}"  />
      <Counter :class="{isVisible:!background}"  />

      <!-- <Modal /> -->
      <Modal @clicked="onClick" />
      <Step @clicked="onClick" />

      <Cursor />
      <Tooltip />
      <Canvas v-if="isTouch" />
    </main>
    <footer class="copyright">
      <Song />
      <p>© 2021 La niche |  Privacy Policy</p>
    </footer>

  </div>
</template>

<script>
import AudioSong from '@/assets/audio/close.mp3'

import Song from './components/Song.vue'
import Info from './components/Info.vue'
import Party from './components/Party.vue'
import Counter from './components/Counter.vue'
import Scene from './components/Scene.vue'
import Loading from './components/Loading.vue'
import Modal from './components/Modal.vue'
import Cursor from './components/Cursor.vue'
import Tooltip from './components/Tooltip.vue'
import Step from './components/Step.vue'
import Canvas from './components/Canvas.vue'

export default {
  name: 'App',
  components: {
    Song,
    Info,
    Party,
    Counter,
    Scene,
    Loading,
    Modal,
    Cursor,
    Tooltip,
    Step,
    Canvas
  },
  computed: {
    background() {
      return this.$store.state.global.background
    },
    overlay() {
      return this.$store.state.global.modal.visible || this.$store.state.global.step.visible
    },
    found() {
      return this.$store.state.global.found
    },
    counter() {
      return this.$store.state.global.counter
    },
    total_counter() {
      return this.$store.state.global.total_counter
    },
    isPlay() {
      return this.$store.state.global.audio
    },
  },
  data() {
    return {
      isTouch: window.APP.isTouchScreen
    }
  },
  beforeMount() {
    this.$store.dispatch('global/get_found')
    if(this.found.length) {
      this.$store.commit('global/SET_COUNTER', this.found.length)
    }
  },
  mounted() {
    this.$audio = new Audio(AudioSong) 
    this.$audio.volume = .6

    document.body.style.height = window.APP.height + "px"
    window.addEventListener('resize', () => document.body.style.height = window.APP.height + "px")
  },
  methods: {
    onClick(id) {
      this.$audio.play()

      if(id === "start" || id === "step-2" && this.found.length) {
        this.$store.commit('global/SET_ID', null)
        this.$store.commit('global/SET_MODAL', null)
        this.$store.commit('global/SET_MODAL_VISIBLE', false)
        this.$store.commit('global/SET_BACKGROUND', false)
        this.$store.commit('global/SET_COUNTER', this.found.length)
        this.$store.commit('global/SET_MOVE', false);
      }
      else if(id) this.$store.commit('global/SET_ID', id)
      else {
        this.$store.commit('global/SET_ID', null)
        this.$store.commit('global/SET_MODAL', null)
        this.$store.commit('global/SET_MODAL_VISIBLE', false)
      }
      if(id === "step-2" && this.isPlay === null) this.$store.commit('global/UPDATE_AUDIO', true)

      if(this.found.length > 0 && this.found.length % 11 === 0 && !this.visible && this.found.length !== this.total_counter) this.$store.commit('global/SET_STEP_VISIBLE', true)

      if(this.found.length === this.total_counter && !this.visible) {
        this.$store.commit('global/SET_ID', "end")
        this.$store.commit('global/SET_BACKGROUND', true)
        this.$store.dispatch('global/clear_found')
      }
    }
  }
}
</script>

<style lang="scss">
body {
  overflow: hidden;
}

*::selection {
  background: $pink;
  color: $white;
}
#app-container {
  font-family:  $primary;
  -webkit-font-smoothing: antialiased;
  color: $black;
  background: $white;

  width: 100vw;
  height: 100vh;

  overflow: hidden;

  .app-background,
  .app-overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background: rgba($white, .8);
    opacity: 0;
    transition: opacity .3s $ease-out-quad;

    pointer-events: none;
    &.visible {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .app-overlay {
    background: rgba($white, .2);
  }

  .copyright {
    display: flex;
    align-items: center;
    
    @include media-min(l) {
      position: absolute;
      left: 50px;
      bottom: 50px;
    }
   

    p {
      font-size: rem(12px);
      font-weight: 400;

      opacity: .4;
      margin-left: 12px;
      @include media-max(l) {
        display: none;
      }
    }
  }
}
</style>
