<template>
  <div class="party"  :class={visible}>
    <aside class="party-modal">
      <button 
      type="button"
      class="party-button-close" 
      :class="{visible}" 
      @click="onClick()"
    >
      <svg width="10" height="10" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94971 6.36415L0.000152588 11.3137L1.41437 12.7279L6.36392 7.77837L11.3137 12.7282L12.7279 11.3139L7.77813 6.36415L12.7281 1.41421L11.3139 0L6.36392 4.94994L1.41422 0.000239372L0 1.41445L4.94971 6.36415Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94971 6.36415L0.000152588 11.3137L1.41437 12.7279L6.36392 7.77837L11.3137 12.7282L12.7279 11.3139L7.77813 6.36415L12.7281 1.41421L11.3139 0L6.36392 4.94994L1.41422 0.000239372L0 1.41445L4.94971 6.36415Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94971 6.36415L0.000152588 11.3137L1.41437 12.7279L6.36392 7.77837L11.3137 12.7282L12.7279 11.3139L7.77813 6.36415L12.7281 1.41421L11.3139 0L6.36392 4.94994L1.41422 0.000239372L0 1.41445L4.94971 6.36415Z" />
      </svg>
      <span class="u-visually-hidden">Fermer la modale d'information</span>
    </button>
      <h2 ref="title" class="party-title">
        {{party?.title}}
      </h2>
      <img 
        width="180" height="180" 
        :src="image"
        alt="Animation d'un chien volant"
      />
      <a class="party-link" :href="party?.url">
        <span class="u-visually-hidden">{{party?.title}}</span>
      </a>
    </aside>
  </div>
</template>

<script>
import info from '@/core/data/info.json'

import CakeImage from '@/assets/gif/cake.gif'

export default {
  name: "Info",

  data() {
    return {
      image: CakeImage,
      party:info.party,
      visible: true
    }
  },
  methods: {
    onClick() {
      this.visible = !this.visible
      setTimeout(() => {
        this.$el.remove()
      }, 3000)
    }
  }
}
</script>

<style lang="scss">
  .party {
    width: 256px;
    /* width: 210px;
    height: 291.25px; */
    position: absolute;
    bottom: 20px;
    left: 20px;
   
    background: $grey;
    border-radius: 25px;

    padding: 12px;
    text-align: center;

    opacity: 0;
    transform: translateX(10px);
    transition: opacity .3s $ease-out-quad, transform .3s $ease-out-quad, background .3s $ease-out-quad;
    
    pointer-events: none;

    

    @include media-min(l) {
      width: 210px;
      
      bottom: 50px;
      right: 50px;
      left: auto;
    }

     &.isVisible.visible {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0);
      /* transition: opacity .3s .4s $ease-out-quad, transform .3s $ease-out-quad, background .3s $ease-out-quad; */
      &:hover {
        /* background: $lightpink; */
        .party-modal {
          border-radius: 18px;
          transform: translateX(0) scale(1.02);
        }
      }
    }

    &-link {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
    }
    &-modal {
      position: relative;
      width: 100%;
      height: 100%;
      background: $white;

      box-shadow: 0px 2.5px 3.75px rgba($black, .1), 0px 0px 30px rgba($black, 0.08);
      border-radius: 12.5px;

      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;

      transition: transform .3s $ease-out-quad, border-radius .3s $ease-out-quad;

      @include media-min(l) {
        padding-top: 40px;
        flex-direction: column;
      }
    }
    &-title {
      max-width: 110px;
      font-size: rem(18px);
      font-weight: 600;

      @include  media-max(l) {
        transform: translateX(-20px)
      }
    }
    &-button-close {
      width: 25px;
      height: 25px;

      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;

      background: $white;

      opacity: 0;
      pointer-events: none;

      transition: opacity .3s .3s $ease-out-quad;
      &:hover {
        svg {
          fill: $pink;
        }
      }
      svg {
        opacity: 0;
        transition: opacity .3s $ease-out-quad;
          fill: $black;
  
      }

      &.visible {
        opacity: 1;
        pointer-events: auto;
        transition: opacity .3s  $ease-out-quad;

        svg {
          opacity: 1;
          transition: opacity .3s .3s  $ease-out-quad, fill .3s $ease-out-quad;
        }
      }
    }

    img {
      pointer-events: none;
      width: 90px;
      height: 90px;
      transform: scaleX(-1.2) scaleY(1.2);
      
      @include media-min(l) {
        transform: translateX(15px) scaleX( -1);
        width: 180px;
        height: 180px;
      }
    }
  }
</style>